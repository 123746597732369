<template>
  <section class="coachesPage">
    <div class="coachesPage__wrapper container">
      <div class="coachesPage__titleBlock">
        <h1 class="coachesPage__title">тренерский</h1>
        <h2 class="coachesPage__title coachesPage__title--grey">
          <svg v-if="screenWidth > 1868" width="258" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m214.927 0-14.82 14.822 17.365 17.366H0v20.96h217.472l-17.365 17.367 14.82 14.822 42.663-42.669L214.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="207"
            height="69"
            viewBox="0 0 207 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M172.34 0.585938L160.484 12.4433L174.376 26.3367L0.398438 26.3367V43.1046L174.376 43.1046L160.484 56.9981L172.34 68.8554L206.471 34.7207L172.34 0.585938Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="104"
            height="35"
            viewBox="0 0 104 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.17 0.398438L80.2421 6.3271L87.1879 13.2738L0.199219 13.2738V21.6578L87.1879 21.6578L80.2421 28.6045L86.17 34.5332L103.235 17.4658L86.17 0.398438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="109.855" height="29.787" fill="none">
            <path
              fill="#E6E6E6"
              d="M94.964 0 89.79 5.174l6.062 6.061H0v7.317h95.852l-6.062 6.061 5.174 5.174 14.891-14.894L94.964 0Z"
            />
          </svg>
          состав
        </h2>

        <img
          v-if="screenWidth <= 430"
          class="coachesPage__teamIMG container"
          src="@/assets/img/Coaches/emptyTeam.webp"
          alt="team"
        />

        <p v-if="screenWidth > 640" class="coachesPage__text">
          Занятия начинаются с обязательной разминки, чтобы разогреть мышцы и связки и подготовить их к нагрузкам. Затем
          ученики делятся по группам между несколькими тренерами и разучивают трюки на 4 зонах парка, который
          сконструирован таким образом, что подходит для всех уровней подготовки: новичку, любителю и профессионалу.
        </p>
      </div>
      <CoachesListComponent :addBtn="true" :screenWidth="screenWidth" />
    </div>
  </section>

  <img
    v-if="screenWidth > 640"
    class="coachesPage__teamIMG container"
    src="@/assets/img/Coaches/emptyTeam.webp"
    alt="team"
  />
  <router-view></router-view>
</template>

<script>
import CoachesListComponent from '@/components/CoachesListComponent.vue';

export default {
  components: { CoachesListComponent },
  name: 'Coaches Page',
  props: {
    screenWidth: Number,
  },
};
</script>

<style lang="scss" scoped></style>
