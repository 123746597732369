<template>
  <section class="servicesAndPriceTitle">
    <div class="servicesAndPriceTitle__wrapper container">
      <div class="servicesAndPriceTitle__titleBlock">
        <h1 class="servicesAndPriceTitle__title">Услуги</h1>
        <h2 class="servicesAndPriceTitle__title servicesAndPriceTitle__title--grey">
          <svg
            v-if="screenWidth > 1686"
            width="124"
            height="74"
            viewBox="0 0 124 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M87.188 0.359375L74.4417 13.1072L89.3767 28.0441L0 28.0441V46.0714L89.3767 46.0714L74.4417 61.0082L87.188 73.7561L123.882 37.0577L87.188 0.359375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="100"
            height="60"
            viewBox="0 0 100 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M70.5492 0.875L60.3522 11.0733L72.3002 23.0228L0.798828 23.0228V37.4446L72.3002 37.4446L60.3522 49.3941L70.5492 59.5924L99.9043 30.2337L70.5492 0.875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="50"
            height="31"
            viewBox="0 0 50 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.2756 0.945312L30.1771 6.04445L36.1511 12.0192L0.400391 12.0192V19.2301L36.1511 19.2301L30.1771 25.2049L35.2756 30.304L49.9531 15.6247L35.2756 0.945312Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            width="52"
            height="34"
            viewBox="0 0 52 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.5532 0L28.7501 5.80381L35.5497 12.6042L0 12.6042V20.8116L35.5497 20.8117L28.7501 27.6121L34.5532 33.4159L51.2591 16.7079L34.5532 0Z"
              fill="#E6E6E6"
            />
          </svg>

          и цены
        </h2>
      </div>
      <img
        v-if="screenWidth > 640"
        class="thumb servicesAndPriceTitle__thumb1"
        src="@/assets/img/thumbs1.svg"
        alt="thumb"
      />
      <img
        v-if="screenWidth > 640"
        class="thumb servicesAndPriceTitle__thumb2"
        src="@/assets/img/thumbs2.svg"
        alt="thumb"
      />
      <img
        v-if="screenWidth > 640"
        class="thumb servicesAndPriceTitle__thumb3"
        src="@/assets/img/thumbs2.svg"
        alt="thumb"
      />
    </div>
  </section>
  <BreadcrumbsComponent :screenWidth="screenWidth" />
  <ServicesGroup v-if="this.$route.path === '/services-and-price' && screenWidth > 640" :screenWidth="screenWidth" />
  <router-view :screenWidth="screenWidth" />

  <LineComponent v-if="this.$route.path === '/services-and-price' && screenWidth <= 430" />
  <AboutParkRulesComponent
    v-if="this.$route.path === '/services-and-price' && screenWidth <= 430"
    :screenWidth="screenWidth"
  />
  <LineComponent v-if="this.$route.path === '/services-and-price' && screenWidth <= 430" />
  <section class="feedback" v-if="this.$route.path === '/services-and-price' && screenWidth <= 430">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
</template>

<script>
import ServicesGroup from '@/pages/ServicesAndPages/Services-GroupPage.vue';
import BreadcrumbsComponent from '@/components/BreadcrumbsComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import AboutParkRulesComponent from '@/components/AboutParkRulesComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';

export default {
  components: {
    BreadcrumbsComponent,
    ServicesGroup,
    LineComponent,
    AboutParkRulesComponent,
    FeedbackSliderComponent,
  },
  props: {
    screenWidth: Number,
  },
};
</script>

<style lang="scss" scoped></style>
