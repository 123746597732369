import axiosInstance from "@/services/axiosInstance";

const coaches = {
  state: {
    coaches: [], // Убираем лишний уровень вложенности
  },
  getters: {},
  mutations: {
    SET_COACHES(state, data) {
      state.coaches = data;
    },
  },
  actions: {
    async fetchCoaches({ commit }) {
      try {
        const response = await axiosInstance.get("/coaches");
        commit("SET_COACHES", response.data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    },
  },
};

export default coaches;


