<template>
	<div class="lessonFormats__blueCard">
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
			src="@/assets/img/BlueCard/blueRightMobile.webp"
			alt="blueCard bg"
			v-show="isShowIndividual"
		/>
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
			src="@/assets/img/BlueCard/lightblueLeftMobile.webp"
			alt="blueCard bg"
			v-show="isShowIndividual"
		/>
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
			src="@/assets/img/BlueCard/blueLeftMobile.webp"
			alt="blueCard bg"
			v-show="isShowGroup"
		/>
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
			src="@/assets/img/BlueCard/lightblueRightMobile.webp"
			alt="blueCard bg"
			v-show="isShowGroup"
		/>

		<div class="lessonFormats__content">
			<div class="lessonFormats__group">
				<button class="lessonFormats__lessonTitle lessonFormats__lessonTitle--marginRight" @click="showGroup">
					групповые тренировки
				</button>
			</div>
			<div class="lessonFormats__description-toggler" v-show="isShowGroup">
				<p class="lessonFormats__description">
					Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию, так и
					педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта подрастающему
					поколению и ко всем находят свой подход.
				</p>
				<button class="lessonFormats__btn ms_booking">
					записаться на тренировку
					<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
							fill="white"
						/>
					</svg>
				</button>
			</div>
			<div class="lessonFormats__individual">
				<button class="lessonFormats__lessonTitle" @click="showIndividual">индивидуальные тренировки</button>
			</div>
			<div class="lessonFormats__description-toggler" v-show="isShowIndividual">
				<p class="lessonFormats__description">
					Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию, так и
					педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта подрастающему
					поколению и ко всем находят свой подход.
				</p>
				<button class="lessonFormats__btn ms_booking">
					записаться на тренировку
					<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
							fill="white"
						/>
					</svg>
				</button>
			</div>
		</div>
		<img class="thumb lessonFormats__thumb11" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb lessonFormats__thumb12" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb lessonFormats__thumb13" src="@/assets/img/thumbs2.svg" alt="thumb" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			isShowIndividual: true,
			isShowGroup: false,
		};
	},

	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},

	methods: {
		showGroup() {
			this.isShowGroup = true;
			this.isShowIndividual = false;
		},
		showIndividual() {
			this.isShowGroup = false;
			this.isShowIndividual = true;
		},
	},
};
</script>

<style lang="scss" scoped></style>
