<template>
  <section v-if="screenWidth > 640" class="groupPage__header">
    <nav class="groupPage__menu container">
      <div class="groupPage__menu-left">
        <router-link to="/group" class="menu-left__link">
          <svg v-if="screenWidth > 1686" width="21" height="12" fill="none">
            <path
              fill="#70C1FF"
              d="m14.64 0-2.02 2.02 2.366 2.365H0V7.24h14.986L12.62 9.606l2.02 2.019 5.81-5.813L14.64 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5082 0L10.8931 1.61525L12.7855 3.50787L0.796875 3.50787V5.79207L12.7855 5.79207L10.8931 7.68469L12.5082 9.29995L17.1576 4.64997L12.5082 0Z"
              fill="#70C1FF"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="9"
            height="5"
            viewBox="0 0 9 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.45526 0.00390625L5.64773 0.811533L6.59393 1.75784L0.599609 1.75784V2.89994L6.59393 2.89994L5.64773 3.84625L6.45526 4.65388L8.77996 2.32889L6.45526 0.00390625Z"
              fill="#70C1FF"
            />
          </svg>
          <span class="menu-left__link--black">Групповые</span>
          <span class="menu-left__link--blue">тренировки</span>
        </router-link>
        <div class="menu-left__link-anchor-list">
          <a href="#advantages" class="menu-left__link-anchor">Преимущества</a>
          <a href="#coaches" class="menu-left__link-anchor">Тренеры</a>
          <a href="#price" class="menu-left__link-anchor">Стоимость</a>
          <a href="#schedule" class="menu-left__link-anchor">Расписание</a>
          <a href="#questions" class="menu-left__link-anchor">Ответы на вопросы</a>
        </div>
      </div>
      <div class="groupPage__menu-right">
        <router-link to="/individual" class="menu-right__link">
          <span class="menu-right__link--grey">индивидуальные</span>
          <svg v-if="screenWidth > 1686" width="22" height="9" fill="none">
            <path
              fill="#BDBDBD"
              d="m15.394 0-2.019 1.47 2.366 1.72H.755V5.27H15.74L13.375 6.99l2.019 1.47 5.812-4.23L15.394 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4144 0L10.7994 1.61525L12.6918 3.50787L0.703125 3.50787V5.79207L12.6918 5.79207L10.7994 7.68469L12.4144 9.29995L17.0638 4.64997L12.4144 0Z"
              fill="#BABABA"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="9"
            height="4"
            viewBox="0 0 9 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.3576 0.402344L5.55008 0.990056L6.49627 1.67869L0.501953 1.67869V2.5098L6.49627 2.5098L5.55008 3.19843L6.3576 3.78614L8.6823 2.09424L6.3576 0.402344Z"
              fill="#BDBDBD"
            />
          </svg>

          <span class="menu-right__link--lightgrey">тренировки</span>
        </router-link>
      </div>
    </nav>
  </section>

  <section v-if="screenWidth <= 430" class="individualPage__headerMobile">
    <img
      class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
      src="@/assets/img/GroupMobilePanel.webp"
      alt="mobile panel bg"
    />
    <nav class="individualPage__headerMobile-navigation container">
      <router-link class="active" to="/group">
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.6391 0L12.6203 2.01907L14.9858 4.38484L0 4.38484V7.24009L14.9858 7.24009L12.6203 9.60586L14.6391 11.6249L20.4509 5.81247L14.6391 0Z"
            fill="#70C1FF"
          />
        </svg>
        групповые тренировки
      </router-link>
      <router-link to="/individual">индивидуальные тренировки</router-link>
    </nav>
  </section>

  <section class="groupPageTitle">
    <div class="groupPageTitle__wrapper container">
      <div class="groupPageTitle__titleBlock">
        <h1 class="groupPageTitle__title">Групповые</h1>
        <h2 class="groupPageTitle__title groupPageTitle__title--grey">
          <svg v-if="screenWidth > 1686" width="379" height="74" fill="none">
            <path
              fill="#E6E6E6"
              d="m342.312.36-12.746 12.748 14.935 14.937H.124v18.027h344.377L329.566 61.01l12.746 12.748 36.694-36.698L342.312.36Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="105"
            height="59"
            viewBox="0 0 105 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.7389 0.0625L64.5419 10.2608L76.4899 22.2103L0.796875 22.2103V36.6321L76.4899 36.6321L64.5419 48.5816L74.7389 58.7799L104.094 29.4212L74.7389 0.0625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="53"
            height="30"
            viewBox="0 0 53 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.3694 0.535156L32.2709 5.6343L38.245 11.609L0.398438 11.609V18.82L38.245 18.82L32.2709 24.7947L37.3694 29.8938L52.047 15.2145L37.3694 0.535156Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="46.063" height="26.184" fill="none">
            <path
              fill="#E6E6E6"
              d="m32.973 0-4.547 4.548 5.328 5.328H0v6.431h33.754l-5.328 5.329 4.547 4.548 13.09-13.092L32.973 0Z"
            />
          </svg>
          тренировки
        </h2>
      </div>
      <img class="thumb groupPageTitle__thumb1" src="@/assets/img/thumbs1.svg" alt="thumb" />
      <img class="thumb groupPageTitle__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb groupPageTitle__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
    </div>
  </section>
  <img
    v-if="screenWidth <= 430"
    class="individualPage__mainImg"
    src="@/assets/img/IndividualPageMainImg.webp"
    alt="main picture"
  />
  <section class="groupPage__workout container">
    <div class="workout-blocks individualPageBlocks">
      <div class="workout-blocks__block workout-blocks__block1">
        <h3 class="workout-blocks__block-title">Как проходит тренировка?</h3>
        <p>
          Занятия начинаются с обязательной разминки, чтобы разогреть мышцы и связки и подготовить их к нагрузкам. Затем
          ученики делятся по группам между несколькими тренерами и разучивают трюки на 4 зонах парка, который
          сконструирован таким образом, что&nbsp;подходит для всех уровней подготовки: новичку, любителю и
          профессионалу.
        </p>
        <h3 class="workout-blocks__block-title">Длительность: 2 часа</h3>
      </div>
      <div class="workout-blocks__block workout-blocks__block2">
        <h3 class="workout-blocks__block-title">Группы делятся по уровню подготовки:</h3>
        <ol>
          <li>
            <span>/1</span>
            ПОДГОТОВИТЕЛЬНАЯ
          </li>
          <li>
            <span>/2</span>
            НАЧИНАЮЩАЯ 
          </li>
          <li>
            <span>/3</span>
            ПРОДОЛЖАЮЩАЯ
          </li>
        </ol>
      </div>
      <div class="workout-blocks__block workout-blocks__block3">
        <h3 class="workout-blocks__block-title workout-blocks__block-title--bigger">О ПАРКЕ</h3>
        <router-link to="/kss-park" class="workout-blocks__block3-btn">
          <svg
            v-if="screenWidth >= 640"
            width="42"
            height="43"
            viewBox="0 0 42 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20.7392" cy="21.6889" r="14.9125" transform="rotate(-34.5432 20.7392 21.6889)" fill="white" />
            <path
              d="M28.4324 22.3447C28.7954 21.9817 28.7954 21.3933 28.4324 21.0303L22.5176 15.1155C22.1546 14.7525 21.5661 14.7525 21.2032 15.1155C20.8402 15.4784 20.8402 16.0669 21.2032 16.4299L26.4608 21.6875L21.2032 26.9451C20.8402 27.3081 20.8402 27.8966 21.2032 28.2595C21.5661 28.6225 22.1546 28.6225 22.5176 28.2595L28.4324 22.3447ZM13.7032 22.6169L27.7752 22.6169L27.7752 20.7581L13.7032 20.7581L13.7032 22.6169Z"
              fill="#252C37"
            />
          </svg>
          <svg
            v-if="screenWidth < 640"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.2629 14.6046C44.8154 22.6705 42.7779 33.7104 34.712 39.2629C26.6461 44.8154 15.6062 42.7779 10.0537 34.712C4.50119 26.6461 6.53871 15.6062 14.6046 10.0537C22.6705 4.50122 33.7104 6.53873 39.2629 14.6046Z"
              fill="#70C1FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M26.773 16.8452L33.8055 23.8777C34.2371 24.3093 34.2371 25.009 33.8055 25.4405L26.773 32.4731C26.3414 32.9046 25.6417 32.9046 25.2102 32.4731C24.7786 32.0415 24.7786 31.3418 25.2102 30.9103L30.3563 25.7642L16.293 25.7642L16.293 23.554L30.3563 23.5541L25.2102 18.4079C24.7786 17.9764 24.7786 17.2767 25.2102 16.8452C25.6417 16.4136 26.3414 16.4136 26.773 16.8452Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <img class="workout-img" v-if="screenWidth > 640" src="@/assets/img/groupMainPhoto.webp" alt="workout" />
  </section>
  <LineComponent />
  <section class="groupPage__advantages" id="advantages">
    <div class="groupPage__advantages-wrapper container">
      <div class="groupPage__advantages-titleBlock">
        <h3 class="groupPage__advantages-title">преимущества</h3>
        <h3 class="groupPage__advantages-title groupPage__advantages-title--grey">
          <svg v-if="screenWidth > 1686" width="78" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m58.797.364-6.632 6.633 7.771 7.773H0v9.38h59.936l-7.771 7.772 6.632 6.633L77.89 19.46 58.797.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="63"
            height="31"
            viewBox="0 0 63 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.03 0.40625L41.7242 5.71275L47.9411 11.9305L-0.0078125 11.9305V19.4346L47.9411 19.4346L41.7242 25.6523L47.03 30.9588L62.3044 15.6825L47.03 0.40625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="32"
            height="16"
            viewBox="0 0 32 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.015 0.707031L21.3621 3.36028L24.4706 6.46914L0.496094 6.46914V10.2212L24.4706 10.2212L21.3621 13.3301L24.015 15.9833L31.6522 8.34517L24.015 0.707031Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="28.462" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M17.99 0 14.35 3.638l4.263 4.263H0v5.145h18.614L14.35 17.31l3.638 3.638 10.473-10.473L17.989 0Z"
            />
          </svg>
          {{ screenWidth <= 430 ? 'занятий в группе' : 'групповых тренировок' }}
        </h3>
      </div>

      <AdvantagesCardComponent :cards="group" />
    </div>
  </section>
  <LineComponent />
  <section class="groupPage__diary">
    <div class="groupPage__diary-wrapper container">
      <div class="groupPage__diary-titleBlock">
        <h3 class="groupPage__diary-title">Дневник</h3>
        <h3 class="groupPage__diary-title groupPage__diary-title--grey">
          <svg v-if="screenWidth > 1686" width="86" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m66.19.764-6.633 6.633 7.771 7.772H0v9.38h67.328l-7.771 7.772 6.632 6.633L85.282 19.86 66.19.764Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="69"
            height="31"
            viewBox="0 0 69 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M52.9513 0.21875L47.6454 5.52525L53.8624 11.743L0 11.743V19.2471L53.8624 19.2471L47.6454 25.4648L52.9513 30.7713L68.2257 15.495L52.9513 0.21875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="35"
            height="16"
            viewBox="0 0 35 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4737 0.105469L23.8208 2.75872L26.9292 5.86757L-0.00195312 5.86757V9.61964L26.9292 9.61964L23.8208 12.7285L26.4737 15.3817L34.1109 7.74361L26.4737 0.105469Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="28.462" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M17.99 0 14.35 3.638l4.263 4.263H0v5.145h18.614L14.35 17.31l3.638 3.638 10.473-10.473L17.989 0Z"
            />
          </svg>
          прогресса
        </h3>
      </div>
      <div class="groupPage__diary-content">
        <article class="groupPage__diary-content-block">
          <p>
            Учет успеваемости у каждого райдера ведется индивидуально в виде дневника прогресса, куда в конце каждого
            занятия ставится оценка за тренировку.каждый месяц подводятся итоги, где ученики получают призы от KSS за
            успехи.
          </p>
          <p>
            В конце семестра проходят зачетные соревнования среди всех групп, где райдеры выполняют нормативы и
            откатывают свою свободную программу. По результатам зачета происходит рост по уровню внутри групп школы.
          </p>
        </article>
        <img
          class="groupPage__diary-img"
          v-if="screenWidth > 640"
          src="@/assets/img/diary.webp"
          alt="Дневник прогресса"
        />
        <img
          class="groupPage__diary-img"
          v-if="screenWidth <= 430"
          src="@/assets/img/diaryMobile.webp"
          alt="Дневник прогресса"
          style="background: #f6f6f6; margin-top: -15px"
        />
        <img class="thumb groupPage__diary-content__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
        <img
          v-if="screenWidth > 640"
          class="thumb groupPage__diary-content__thumb5"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          v-if="screenWidth > 640"
          class="thumb groupPage__diary-content__thumb6"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
      </div>
    </div>
  </section>
  <LineComponent />
  <section class="coaches" id="coaches">
    <div class="coaches__wrapper container">
      <div class="coaches__titleBlock">
        <h3 class="coaches__title">Тренерский</h3>
        <h3 class="coaches__title coaches__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="198" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m178.131.814-6.633 6.634 7.772 7.772H.08v9.38h179.19l-7.772 7.772 6.633 6.633 19.093-19.095L178.131.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="158"
            height="31"
            viewBox="0 0 158 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142.707 0.390625L137.401 5.69712L143.618 11.9148L0.265625 11.9148V19.419L143.618 19.419L137.401 25.6367L142.707 30.9432L157.981 15.6669L142.707 0.390625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="80"
            height="16"
            viewBox="0 0 80 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M71.8515 0.0273438L69.1986 2.68059L72.307 5.78945L0.630859 5.78945V9.54152L72.307 9.54152L69.1986 12.6504L71.8515 15.3036L79.4887 7.66548L71.8515 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="80" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M68.99 0 65.35 3.638l4.263 4.263H.5v5.145h69.114L65.35 17.31l3.638 3.638 10.473-10.473L68.989 0Z"
            />
          </svg>
          состав
        </h3>
      </div>
      <CoachesListSliderComponent v-if="screenWidth > 1686" :screenWidth="screenWidth" />
      <CoachesSliderTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" :screenWidth="screenWidth" />
      <CoachesSliderMobileComponent v-if="screenWidth <= 430" :screenWidth="screenWidth" />
    </div>
  </section>
  <LineComponent v-if="screenWidth > 640" />
  <section class="price" id="price">
    <div class="price__wrapper container">
      <div class="price__titleBlock">
        <h3 class="price__title">стоимость</h3>
        <h3 class="price__title price__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="83"
            height="32"
            viewBox="0 0 83 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.1059 0.890625L61.8001 6.19712L68.0171 12.4148L0.601562 12.4148V19.919L68.0171 19.919L61.8001 26.1367L67.1059 31.4432L82.3804 16.1669L67.1059 0.890625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="42"
            height="17"
            viewBox="0 0 42 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.949219L31.4001 3.60247L34.5085 6.71132L0.800781 6.71132V10.4634L34.5085 10.4634L31.4001 13.5722L34.053 16.2255L41.6902 8.58736L34.053 0.949219Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="28.462" height="20.947" fill="none">
            <path
              fill="#DFDFDF"
              d="M17.99 0 14.35 3.638l4.263 4.263H0v5.145h18.614L14.35 17.31l3.638 3.638 10.473-10.473L17.989 0Z"
            />
          </svg>
          тренировки
        </h3>
      </div>
      <PriceComponent :price="price" v-if="screenWidth > 1686" />
      <PriceComponentTablet :price="price" v-if="screenWidth <= 1686 && screenWidth >= 640" />
      <PriceComponentMobile :price="price" v-if="screenWidth <= 430" />
    </div>
  </section>
  <LineComponent />
  <section class="schedule" id="schedule">
    <div class="schedule__wrapper container">
      <div class="schedule__titleBlock">
        <h3 class="schedule__title">Расписание</h3>
        <h3 class="schedule__title schedule__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="83"
            height="31"
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M66.9966 0.28125L61.6907 5.58775L67.9077 11.8055L0.492188 11.8055V19.3096L67.9077 19.3096L61.6907 25.5273L66.9966 30.8338L82.271 15.5575L66.9966 0.28125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.546875L31.4001 3.20012L34.5085 6.30898L0.800781 6.30898V10.061L34.5085 10.061L31.4001 13.1699L34.053 15.8232L41.6902 8.18502L34.053 0.546875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="53.461" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M42.989 0 39.35 3.638l4.263 4.263H0v5.145h43.614L39.35 17.31l3.638 3.638L53.46 10.474 42.99 0Z"
            />
          </svg>
          KSS PARK {{ screenWidth <= 430 ? '' : '// м. нагорная' }}
        </h3>
      </div>
      <button v-if="screenWidth > 640" class="schedule__linkToRules" @click="openHoverRules">
        <svg v-if="screenWidth > 1686" width="16" height="16" fill="none">
          <circle cx="7.839" cy="8.144" r="7.839" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1686 && screenWidth >= 1356"
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.87275" cy="6.73213" r="6.27119" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1355 && screenWidth >= 640"
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.93638" cy="3.86216" r="3.1356" fill="#0091FF" />
        </svg>
        Правила посещения парка
      </button>
      <div v-if="screenWidth <= 430" class="schedule__available">
        <span class="schedule__available-group">групповая тренировка</span>
        <span class="schedule__available-no">записи больше нет</span>
        <span class="schedule__available-yes">доступен для записи</span>
      </div>
    </div>
    <div v-if="screenWidth > 640" class="hoverRules__wrapper container">
      <HoverRulesComponent v-show="isShowHoverRules" />
    </div>
    <div class="schedule__table container">
    <img :src='`http://195.43.142.74/images/Schedules/schedule-group.png`' alt="groupSchedule">
      <!-- <GroupScheduleComponent v-if="screenWidth > 1686" />
      <GroupScheduleTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" />
      <GroupScheduleMobileComponent v-if="screenWidth <= 430" /> -->
    </div>
    <div class="container" v-if="screenWidth <= 430">
      <router-link class="schedule__linkToRulesMobile" to="/rules">
        правила посещения ТРЕНИРОВКИ
        <svg width="43.974" height="12.988" fill="none">
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M0 5.52h42.995v1.95H0V5.52Zm41.617.974L36.79 1.668a.966.966 0 0 1 0-1.378.966.966 0 0 1 1.379 0l5.515 5.515a.966.966 0 0 1 0 1.379l-5.516 5.515a.966.966 0 0 1-1.378 0 .966.966 0 0 1 0-1.38l4.826-4.825Z"
          />
        </svg>
      </router-link>
    </div>
  </section>
  <LineComponent />
  <img
    v-if="screenWidth > 640"
    class="championBanner container"
    src="@/assets/img/championBanner.png"
    alt="Мы растим чемпионов"
  />
  <img
    v-if="screenWidth <= 430"
    class="championBanner container"
    src="@/assets/img/championBannerMobile.webp"
    alt="Мы растим чемпионов"
  />
  <LineComponent />
  <section class="feedback">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="45"
            height="31"
            viewBox="0 0 45 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7044 0.203125L24.3986 5.50962L30.6155 11.7273L0 11.7273V19.2315L30.6155 19.2315L24.3986 25.4492L29.7044 30.7557L44.9788 15.4794L29.7044 0.203125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
  <LineComponent />
  <section class="partners">
    <div class="partners__wrapper container">
      <div class="partners__titleBlock">
        <h3 class="partners__title">партнёры</h3>
        <h3 class="partners__title partners__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="61" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="M41.051.814 34.42 7.448l7.771 7.772H.5v9.38h41.69l-7.77 7.772 6.631 6.633L60.145 19.91 41.05.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="49"
            height="31"
            viewBox="0 0 49 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.0408 0.25L27.735 5.5565L33.952 11.7742L0.599609 11.7742V19.2783L33.952 19.2783L27.735 25.4961L33.0408 30.8026L48.3153 15.5263L33.0408 0.25Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="33" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M22.49 0 18.85 3.638l4.263 4.263H0v5.145h23.114L18.85 17.31l3.638 3.638 10.473-10.473L22.489 0Z"
            />
          </svg>
          самокат-школы
        </h3>
      </div>
    </div>
    <PartnersComponent v-if="screenWidth > 1686" />
    <PartnersSliderTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" />
    <PartnersSliderMobileComponent v-if="screenWidth <= 430" />
  </section>
  <LineComponent />
  <section class="questions container" id="questions">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #57606f" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
</template>

<script>
import LineComponent from '@/components/LineComponent.vue';
import AdvantagesCardComponent from '@/components/AdvantagesCardComponent.vue';
import { mapState, mapMutations } from 'vuex';
import CoachesListComponent from '@/components/CoachesListComponent.vue';
import PartnersComponent from '@/components/PartnersComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import PriceComponent from '@/components/PriceComponent.vue';
import GroupScheduleComponent from '@/components/GroupScheduleComponent.vue';
import GroupScheduleMobileComponent from '@/components/GroupScheduleMobileComponent.vue';
import HoverRulesComponent from '@/components/HoverRulesComponent.vue';
import PartnersSliderMobileComponent from '@/components/PartnersSliderMobileComponent.vue';
import CoachesSliderMobileComponent from '@/components/CoachesSliderMobileComponent.vue';
import PriceComponentMobile from '@/components/PriceComponentMobile.vue';
import CoachesListSliderComponent from '@/components/CoachesListSliderComponent.vue';
import PartnersSliderTabletComponent from '@/components/PartnersSliderTabletComponent.vue';
import CoachesSliderTabletComponent from '@/components/CoachesSliderTabletComponent.vue';
import PriceComponentTablet from '@/components/PriceComponentTablet.vue';
import GroupScheduleTabletComponent from '@/components/GroupScheduleTabletComponent.vue';

export default {
  components: {
    LineComponent,
    AdvantagesCardComponent,
    CoachesListComponent,
    PartnersComponent,
    FeedbackSliderComponent,
    QuestionComponent,
    PriceComponent,
    GroupScheduleComponent,
    GroupScheduleMobileComponent,
    HoverRulesComponent,
    PartnersSliderMobileComponent,
    CoachesSliderMobileComponent,
    PriceComponentMobile,
    CoachesListSliderComponent,
    PartnersSliderTabletComponent,
    CoachesSliderTabletComponent,
    PriceComponentTablet,
    GroupScheduleTabletComponent,
  },
  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      group: (state) => state.advantagesCards.group,
      price: (state) => state.price.group,
    }),
    ...mapState(['isShowHoverRules']),
  },

  methods: {
    ...mapMutations(['openHoverRules']),
  },
};
</script>

<style lang="scss" scoped></style>
