import axiosInstance from "@/services/axiosInstance";

const eventCards = {
  state: {
    cards: [], // Убираем лишний уровень вложенности
  },
  getters: {},
  mutations: {
    SET_EVENTS(state, data) {
      state.cards = data;
    },
  },
  actions: {
    async eventsData({ commit }) {
      try {
        const response = await axiosInstance.get("/events");
        commit("SET_EVENTS", response.data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    },
  },
};

export default eventCards;
