<template>
  <section class="price" id="price">
    <div class="price__wrapper container">
      <div class="price__titleBlock">
        <h3 class="price__title">стоимость</h3>
        <h3 class="price__title price__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="83"
            height="31"
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.104 0.25L61.7982 5.5565L68.0151 11.7742L0.599609 11.7742V19.2783L68.0151 19.2783L61.7982 25.4961L67.104 30.8026L82.3784 15.5263L67.104 0.25Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.125L31.4001 2.77825L34.5085 5.88711L0.800781 5.8871V9.63917L34.5085 9.63917L31.4001 12.748L34.053 15.4013L41.6902 7.76314L34.053 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="28.462" height="20.947" fill="none">
            <path
              fill="#DFDFDF"
              d="M17.99 0 14.35 3.638l4.263 4.263H0v5.145h18.614L14.35 17.31l3.638 3.638 10.473-10.473L17.989 0Z"
            />
          </svg>
          тренировки
        </h3>
      </div>
      <PriceComponent :price="price" v-if="screenWidth > 1686" />
      <PriceComponentTablet :price="price" v-if="screenWidth >= 640 && screenWidth <= 1686" />
      <PriceComponentMobile :price="price" v-if="screenWidth <= 430" />
    </div>
  </section>
  <LineComponent />
  <section class="schedule" id="schedule">
    <div class="schedule__wrapper container">
      <div class="schedule__titleBlock">
        <h3 class="schedule__title">Расписание</h3>
        <h3 class="schedule__title schedule__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="83"
            height="32"
            viewBox="0 0 83 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.4067 0.484375L62.1009 5.79087L68.3178 12.0086L0.902344 12.0086V19.5127L68.3178 19.5127L62.1009 25.7304L67.4067 31.0369L82.6812 15.7607L67.4067 0.484375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.546875L31.4001 3.20012L34.5085 6.30898L0.800781 6.30898V10.061L34.5085 10.061L31.4001 13.1699L34.053 15.8232L41.6902 8.18502L34.053 0.546875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="53.461" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M42.989 0 39.35 3.638l4.263 4.263H0v5.145h43.614L39.35 17.31l3.638 3.638L53.46 10.474 42.99 0Z"
            />
          </svg>
          KSS PARK {{ screenWidth <= 430 ? '' : '// м. нагорная' }}
        </h3>
      </div>
      <button v-if="screenWidth > 640" class="schedule__linkToRules" @click="openHoverRules">
        <svg v-if="screenWidth > 1686" width="16" height="16" fill="none">
          <circle cx="7.839" cy="8.144" r="7.839" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1686 && screenWidth >= 1356"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.66963" cy="6.39619" r="6.27119" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1355 && screenWidth >= 640"
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.93638" cy="3.86216" r="3.1356" fill="#0091FF" />
        </svg>
        Правила посещения парка
      </button>
      <div v-if="screenWidth <= 430" class="schedule__available">
        <span class="schedule__available-yes">доступен для записи</span>
        <span class="schedule__available-no">записи больше нет</span>
        <span class="schedule__available-group">групповая тренировка</span>
      </div>
    </div>
    <div v-if="screenWidth > 640" class="hoverRules__wrapper container">
      <HoverRulesComponent v-show="isShowHoverRules" />
    </div>
    <div class="schedule__table container">
      <!-- <IndividualScheduleComponent v-if="screenWidth > 1686" />
      <IndividualScheduleTabletComponent v-if="screenWidth >= 640 && screenWidth <= 1686" />
      <IndividualScheduleMobileComponent v-if="screenWidth <= 430" /> -->
			<img src="@/assets/img/groupSchedule.webp" alt="Расписание" />
    </div>
    <div class="container" v-if="screenWidth <= 430">
      <router-link class="schedule__linkToRulesMobile" to="/rules">
        правила посещения ТРЕНИРОВКИ
        <svg width="43.974" height="12.988" fill="none">
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M0 5.52h42.995v1.95H0V5.52Zm41.617.974L36.79 1.668a.966.966 0 0 1 0-1.378.966.966 0 0 1 1.379 0l5.515 5.515a.966.966 0 0 1 0 1.379l-5.516 5.515a.966.966 0 0 1-1.378 0 .966.966 0 0 1 0-1.38l4.826-4.825Z"
          />
        </svg>
      </router-link>
    </div>
  </section>
  <LineComponent />
  <AdditionalComponent :additional="additional" :screenWidth="screenWidth" />

  <LineComponent v-if="screenWidth <= 430" />
  <AboutParkRulesComponent v-if="screenWidth <= 430" :screenWidth="screenWidth" />
  <LineComponent v-if="screenWidth <= 430" />
  <section class="feedback" v-if="screenWidth <= 430">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AdditionalComponent from '@/components/AdditionalComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import PriceComponent from '@/components/PriceComponent.vue';
import HoverRulesComponent from '@/components/HoverRulesComponent.vue';
import PriceComponentMobile from '@/components/PriceComponentMobile.vue';
import IndividualScheduleComponent from '@/components/IndividualScheduleComponent.vue';
import IndividualScheduleMobileComponent from '@/components/IndividualScheduleMobileComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';
import AboutParkRulesComponent from '@/components/AboutParkRulesComponent.vue';
import PriceComponentTablet from '@/components/PriceComponentTablet.vue';
import IndividualScheduleTabletComponent from '@/components/IndividualScheduleTabletComponent.vue';

export default {
  components: {
    AdditionalComponent,
    LineComponent,
    PriceComponent,
    HoverRulesComponent,
    PriceComponentMobile,
    IndividualScheduleComponent,
    IndividualScheduleMobileComponent,
    IndividualScheduleTabletComponent,
    FeedbackSliderComponent,
    AboutParkRulesComponent,
    PriceComponentTablet,
  },
  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      group: (state) => state.advantagesCards.group,
      price: (state) => state.price.individual,
      additional: (state) => state.price.additional,
    }),
    ...mapState(['isShowHoverRules']),
  },

  methods: {
    ...mapMutations(['openHoverRules']),
  },
};
</script>

<style lang="scss" scoped></style>
