<template>
	<div class="line container"></div>
</template>

<style lang="scss" scoped>
.line {
	min-height: 1px;
	background-color: #d4d4d4;
}
</style>
