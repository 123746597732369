import axiosInstance from "@/services/axiosInstance";

const trainingVideo = {
  state: {
    videoState: [],
  },
  getters: {},
  mutations: {
    SET_VIDEO(state, data) {
      state.videoState = data;
    }
  },
  actions: {
    async fetchVideo({ commit }) {
      try {
        const response = await axiosInstance.get("/trainingvideos");
        commit("SET_VIDEO", response.data);
        
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
  },
},
};

export default trainingVideo;
