<template>
  <section class="additional">
    <div class="additional__wrapper container">
      <div class="additional__titleBlock">
        <h3 class="additional__title">
          {{ this.$route.path === '/services-and-price/rental' ? title : 'дополнительные' }}
        </h3>
        <h3 class="additional__title additional__title--grey">
          <svg
            v-if="screenWidth > 1686 && this.$route.path === '/services-and-price/rental'"
            width="52"
            height="39"
            viewBox="0 0 52 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.1305 0.8125L25.4982 7.44562L33.2694 15.2178L0.5 15.2178V24.5979L33.2694 24.5979L25.4982 32.3701L32.1305 39.0032L51.2235 19.9079L32.1305 0.8125Z"
              fill="#E6E6E6"
            />
          </svg>

          <svg
            v-else-if="screenWidth > 1686"
            width="164"
            height="39"
            viewBox="0 0 164 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M144.63 0.8125L137.998 7.44562L145.769 15.2178L0 15.2178V24.5979L145.769 24.5979L137.998 32.3701L144.63 39.0032L163.724 19.9079L144.63 0.8125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="132"
            height="32"
            viewBox="0 0 132 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M116.105 0.65625L110.799 5.96275L117.016 12.1805L0.400391 12.1805V19.6846L117.016 19.6846L110.799 25.9023L116.105 31.2088L131.379 15.9325L116.105 0.65625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8514 0.125L10.1985 2.77825L13.307 5.88711L0.199219 5.8871V9.63917L13.307 9.63917L10.1985 12.748L12.8514 15.4013L20.4886 7.76314L12.8514 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="53.461" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M42.989 0 39.35 3.638l4.263 4.263H0v5.145h43.614L39.35 17.31l3.638 3.638L53.46 10.474 42.99 0Z"
            />
          </svg>
          {{ this.$route.path === '/services-and-price/rental' ? subtitle : 'услуги' }}
        </h3>
      </div>
    </div>
    <div class="additional-items__wrapper container">
      <ul class="additional-items">
        <li class="additional-item" v-for="item in additional" :key="item.id">
          <img :src="item.pic" :alt="item.title" />
          <h4 v-if="screenWidth > 640">{{ item.title }}</h4>
          <p v-if="screenWidth > 640">{{ item.time }}</p>
          <div>
            <h4 v-if="screenWidth <= 430">{{ item.title }}</h4>
            <p v-if="screenWidth <= 430">{{ item.time }}</p>
          </div>
          <span>{{ item.price }}</span>
        </li>
      </ul>
      <div class="additional-items__textBlock">
        <p class="additional-items__text">
          KSS PARK является территорией повышенной опасности. Администрация парка не несёт ответственности за любые
          возможные травмы катающихся и других посетителей, независимо от причин и обстоятельств возникновения таких
          травм.
        </p>
        <p class="additional-items__text additional-items__text--bold">
          Просьба, использовать специальную защиту: наколенники и налокотники.
        </p>
        <img class="thumb thumb4" src="@/assets/img/thumbs1.svg" alt="thumb" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    screenWidth: Number,
    additional: Array,
    title: String,
    subtitle: String,
  },
};
</script>

<style lang="scss" scoped></style>
