<template>
	<swiper
		:navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
		:slidesPerView="3.5"
		:centeredSlides="false"
		:spaceBetween="15"
		:grabCursor="true"
		class="mySwiper partnersSliderMobile__swiper"
	>
		<swiper-slide v-for="partner in partners" :key="partner.id">
			<img v-tippy="partner.title" :src="partner.img" :alt="partner.title" />
		</swiper-slide>
	</swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
	props: {
		screenWidth: Number,
	},

	components: {
		Swiper,
		SwiperSlide,
	},

	computed: {
		...mapState({
			partners: (state) => state.partners.partnersMobile,
		}),
	},
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';

.swiper {
	height: 160px;
}
</style>
