import axiosInstance from "@/services/axiosInstance";

const coach = {
  state: {
    coaches: [],
  },
  getters: {},
  mutations: {
    SET_DATA(state, data) {
      state.coaches = data;
    },
  },
  actions: {
    async fetchCoach({ commit }) {
      try {
        const response = await axiosInstance.get("/coaches");

        commit("SET_DATA", response.data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    },
  },
};

export default coach;
