<template>
  <section class="questionsPage">
    <div class="questionsPage__wrapper container">
      <div class="questionsPage__titleBlock">
        <h1 class="questionsPage__title">часто задаваемые</h1>
        <h2 class="questionsPage__title questionsPage__title--grey">
          <svg v-if="screenWidth > 1686" width="167" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m123.927 0-14.82 14.822 17.365 17.366H0v20.96h126.472l-17.365 17.367 14.82 14.822 42.663-42.669L123.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="134"
            height="69"
            viewBox="0 0 134 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M99.7431 0.617188L87.8873 12.4745L101.779 26.3679L0.601562 26.3679V43.1359L101.779 43.1359L87.8873 57.0293L99.7431 68.8866L133.874 34.7519L99.7431 0.617188Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="68"
            height="35"
            viewBox="0 0 68 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.3716 0.804688L44.4437 6.73335L51.3895 13.6801L0.800781 13.6801V22.064L51.3895 22.064L44.4437 29.0107L50.3716 34.9394L67.4368 17.8721L50.3716 0.804688Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="109" height="27" fill="none">
            <path
              fill="#E6E6E6"
              d="m95.47.84-4.41 4.412 5.168 5.17H0v6.238h96.228l-5.168 5.168 4.41 4.412 12.698-12.7L95.47.84Z"
            />
          </svg>
          вопросы
        </h2>
      </div>
    </div>
  </section>

  <section class="faq container faq--blue">
    <div class="faq__wrapper">
      <FAQComponent :screenWidth="screenWidth" />
    </div>
  </section>
  <QuestionPhoneComponent :screenWidth="screenWidth" />
</template>

<script>
import LineComponent from '@/components/LineComponent.vue';
import FAQComponent from '@/components/FAQComponent.vue';
import QuestionPhoneComponent from '@/components/QuestionPhoneComponent.vue';

export default {
  components: {
    FAQComponent,
    QuestionPhoneComponent,
    LineComponent,
  },
  props: {
    screenWidth: Number,
  },
};
</script>

<style lang="scss" scoped></style>
