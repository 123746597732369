<template>
  <div class="ticker">
    <h3 class="ticker__title" v-for="i in 8" :key="i">
      ОТКРЫТ НАБОР В САМОКАТ-ШКОЛУ!
      <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
        <path
          fill="#fff"
          d="M35.543 8.933a1 1 0 0 0 0-1.415l-6.364-6.363a1 1 0 1 0-1.415 1.414l5.657 5.657-5.657 5.656a1 1 0 0 0 1.415 1.415l6.364-6.364ZM.335 9.226h34.5v-2H.335v2Z"
        />
      </svg>
      <svg
        v-if="screenWidth >= 1356 && screenWidth <= 1686"
        width="29"
        height="13"
        viewBox="0 0 29 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.7477 6.9485C29.0601 6.63608 29.0601 6.12955 28.7477 5.81713L23.6565 0.725958C23.3441 0.413539 22.8376 0.413539 22.5252 0.725958C22.2128 1.03838 22.2128 1.54491 22.5252 1.85733L27.0507 6.38281L22.5252 10.9083C22.2128 11.2207 22.2128 11.7272 22.5252 12.0397C22.8376 12.3521 23.3441 12.3521 23.6565 12.0397L28.7477 6.9485ZM0.582031 7.18281H28.182V5.58281H0.582031V7.18281Z"
          fill="white"
        />
      </svg>
      <svg
        v-if="screenWidth >= 640 && screenWidth < 1356"
        width="15"
        height="7"
        viewBox="0 0 15 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7078 4.16956C14.8641 4.01335 14.8641 3.76009 14.7078 3.60388L12.1623 1.05829C12.006 0.902082 11.7528 0.902082 11.5966 1.05829C11.4404 1.2145 11.4404 1.46777 11.5966 1.62398L13.8593 3.88672L11.5966 6.14946C11.4404 6.30567 11.4404 6.55894 11.5966 6.71515C11.7528 6.87136 12.006 6.87136 12.1623 6.71515L14.7078 4.16956ZM0.625 4.28672H14.425V3.48672H0.625V4.28672Z"
          fill="white"
        />
      </svg>

      <svg v-if="screenWidth < 640" width="22" height="9" fill="none">
        <path
          fill="#fff"
          d="M21.461 5.066a.584.584 0 0 0 0-.825L17.746.526a.584.584 0 1 0-.825.825l3.302 3.302-3.302 3.302a.584.584 0 1 0 .825.826l3.715-3.715Zm-20.56.171h20.148V4.07H.9v1.167Z"
        />
      </svg>
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    screenWidth: Number,
  },
};
</script>

<style lang="scss" scoped></style>
