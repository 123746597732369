import RULES from '@/assets/documents/Rules.docx';
import ADULT from '@/assets/documents/Adult.docx';
import CHILD from '@/assets/documents/Child.docx';

const documents = {
  state: {
    documents: [
      {
        id: 1,
        file: RULES,
      },
      {
        id: 2,
        file: ADULT,
      },
      {
        id: 3,
        file: CHILD,
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default documents;
