<template>
	<div class="hoverRules">
		<div class="hoverRules__top">
			<h3 class="hoverRules__bigTitle">ДЛЯ ПОСЕЩЕНИЯ НЕОБХОДИМО</h3>
			<button class="hoverRules__closeBtn" @click="openHoverRules">
				<svg width="21" height="21" fill="none">
					<path
						fill="#D9D9D9"
						fill-rule="evenodd"
						d="M2.843.96.656 3.147l7.606 7.606L.656 18.36l2.187 2.188 7.606-7.606 7.606 7.606 2.188-2.188-7.606-7.606 7.606-7.606L18.055.96 10.45 8.566 2.843.96Z"
						clip-rule="evenodd"
					/>
				</svg>
			</button>
		</div>
		<LineComponent />
		<div class="hoverRules__secondPart">
			<h4 class="hoverRules__title">Ознакомиться с правилами парка</h4>
			<router-link class="hoverRules__btn" to="/rules">Правила парка</router-link>
		</div>
		<LineComponent />
		<div class="hoverRules__thirdPart">
			<h4 class="hoverRules__title">Заполнить расписку о том, что Вы информированы о правилах посещения парка</h4>
			<div class="hoverRules__buttonsList">
				<button class="hoverRules__btn">Расписка для детей</button>
				<button class="hoverRules__btn">Расписка для родителей</button>
			</div>
		</div>
		<LineComponent />
		<div class="hoverRules__bottom">
			<p class="hoverRules__text">
				Для несовершеннолетних заполняется родителями. <br /><br />
				Для совершеннолетних самостоятельно: при себе документ, удостоверяющий личность и возраст
			</p>
		</div>
	</div>
</template>

<script>
import LineComponent from './LineComponent.vue';
import { mapMutations } from 'vuex';

export default {
	components: { LineComponent },
	props: {
		isShowHoverRules: Boolean,
	},

	methods: {
		...mapMutations(['openHoverRules']),
	},
};
</script>

<style lang="scss" scoped></style>
