<template>
  <section class="aboutPark-rules">
    <div class="aboutPark-rules__wrapper container">
      <img v-if="screenWidth > 640" class="aboutPark-rules__bg" src="@/assets/img/RulesBG.webp" alt="" />
      <img v-if="screenWidth <= 430" class="aboutPark-rules__bg" src="@/assets/img/RulesBGMobile.svg" alt="" />
      <div class="aboutPark-rules__leftSide">
        <div class="aboutPark-rules__titleBlock">
          <h3 class="aboutPark-rules__title">ПРАВИЛА ПОСЕЩЕНИЯ</h3>
          <h3 class="aboutPark-rules__title aboutPark-rules__title--blue">
            <svg v-if="screenWidth > 1686" width="135" height="47" fill="none">
              <path
                fill="#F1F1F1"
                d="m111.815.977-7.871 7.871 9.223 9.224H.174v11.132h112.993l-9.223 9.224 7.871 7.872 22.659-22.662L111.815.977Z"
              />
            </svg>
            <svg
              v-if="screenWidth >= 1356 && screenWidth <= 1686"
              width="108"
              height="37"
              viewBox="0 0 108 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M89.856 0.179688L83.5592 6.47723L90.9373 13.8562L0.542969 13.8562V22.7618L90.9373 22.7618L83.5592 30.1407L89.856 36.4383L107.983 18.309L89.856 0.179688Z"
                fill="#F1F1F1"
              />
            </svg>
            <svg
              v-if="screenWidth >= 640 && screenWidth < 1356"
              width="54"
              height="19"
              viewBox="0 0 54 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.928 0.589844L41.7796 3.73862L45.4686 7.42809L0.271484 7.42809V11.8809L45.4686 11.8809L41.7796 15.5704L44.928 18.7191L53.9915 9.65449L44.928 0.589844Z"
                fill="#F1F1F1"
              />
            </svg>
            <svg v-if="screenWidth <= 430" width="53" height="19" fill="none">
              <path
                fill="#F1F1F1"
                d="M43.825.657 40.74 3.743l3.615 3.615H.068v4.363h44.287l-3.615 3.615 3.085 3.086 8.881-8.883-8.88-8.882Z"
              />
            </svg>
            KSS PARK
          </h3>
        </div>
      </div>
      <div class="aboutPark-rules__rightSide">
        <div class="aboutPark-rules__rightSide-point">
          <div class="aboutPark-rules__rightSide-number">1</div>
          <p class="aboutPark-rules__rightSide-text">
            Расписка от родителей о том, что они и ученик ознакомлены с правилами посещения скейт-парка:
          </p>
        </div>
        <div class="aboutPark-rules__rightSide-btns">
          <a :href="documents[0].file" class="aboutPark-rules__rightSide-btn">Правила парка</a>
          <a :href="documents[1].file" class="aboutPark-rules__rightSide-btn">Расписка для детей</a>
          <a :href="documents[2].file" class="aboutPark-rules__rightSide-btn">Расписка для родителей</a>
        </div>
        <div class="aboutPark-rules__rightSide-point">
          <div class="aboutPark-rules__rightSide-number">2</div>
          <p class="aboutPark-rules__rightSide-text">Копия свидетельства о рождении.</p>
        </div>
        <router-link v-if="screenWidth > 640" to="rules" class="aboutPark-rules__rightSide-fullRules">
          ПОЛНЫЙ ПЕРЕЧЕНЬ ПРАВИЛ ПОСЕЩЕНИЯ
          <span>
            ТРЕНИРОВКИ KSS SCHOOL
            <svg v-if="screenWidth > 1686" width="109" height="16" fill="none">
              <path
                fill="#fff"
                d="M108.707 8.707a1 1 0 0 0 0-1.414L102.343.929a1 1 0 1 0-1.414 1.414L106.586 8l-5.657 5.657a1 1 0 1 0 1.414 1.414l6.364-6.364ZM0 9h108V7H0v2Z"
              />
            </svg>
            <svg
              v-if="screenWidth >= 1356 && screenWidth <= 1686"
              width="88"
              height="13"
              viewBox="0 0 88 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M87.1649 6.77662C87.4773 6.4642 87.4773 5.95767 87.1649 5.64525L82.0737 0.554083C81.7613 0.241664 81.2548 0.241664 80.9424 0.554083C80.6299 0.866503 80.6299 1.37303 80.9424 1.68545L85.4678 6.21094L80.9424 10.7364C80.6299 11.0488 80.6299 11.5554 80.9424 11.8678C81.2548 12.1802 81.7613 12.1802 82.0737 11.8678L87.1649 6.77662ZM0.199219 7.01094H86.5992V5.41094H0.199219V7.01094Z"
                fill="white"
              />
            </svg>
            <svg
              v-if="screenWidth >= 640 && screenWidth < 1356"
              width="45"
              height="7"
              viewBox="0 0 45 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.0825 3.8805C44.2387 3.72429 44.2387 3.47102 44.0825 3.31481L41.5369 0.769229C41.3807 0.613019 41.1274 0.613019 40.9712 0.769229C40.815 0.925439 40.815 1.1787 40.9712 1.33491L43.2339 3.59766L40.9712 5.8604C40.815 6.01661 40.815 6.26987 40.9712 6.42608C41.1274 6.58229 41.3807 6.58229 41.5369 6.42608L44.0825 3.8805ZM0.599609 3.99766H43.7996V3.19766H0.599609V3.99766Z"
                fill="white"
              />
            </svg>
          </span>
        </router-link>
        <router-link v-if="screenWidth <= 430" to="rules" class="aboutPark-rules__rightSide-fullRules">
          ПРАВИЛА ПОСЕЩЕНИЯ
          <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    screenWidth: Number,
  },

	computed: {
    ...mapState({
			documents: (state) => state.documents.documents,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
