<template>
	<div class="lessonFormats__blueCard">
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
			src="@/assets/img/BlueCard/blueRight.webp"
			alt="blueCard bg"
			v-show="isShowIndividual"
		/>
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
			src="@/assets/img/BlueCard/lightblueLeft.webp"
			alt="blueCard bg"
			v-show="isShowIndividual"
		/>
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
			src="@/assets/img/BlueCard/blueLeft.webp"
			alt="blueCard bg"
			v-show="isShowGroup"
		/>
		<img
			class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
			src="@/assets/img/BlueCard/lightblueRight.webp"
			alt="blueCard bg"
			v-show="isShowGroup"
		/>

		<div class="lessonFormats__content">
			<div class="lessonFormats__group">
				<button class="lessonFormats__lessonTitle lessonFormats__lessonTitle--marginRight" @click="showGroup">
					<svg v-show="isShowGroup" xmlns="http://www.w3.org/2000/svg" width="61" height="16" fill="none">
						<path
							fill="#fff"
							d="M60.707 8.707a1 1 0 0 0 0-1.414L54.343.929a1 1 0 1 0-1.414 1.414L58.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h60V7H0v2Z"
						/>
					</svg>
					групповые тренировки
				</button>
				<div class="lessonFormats__description-toggler" v-show="isShowGroup">
					<img
						class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--linesGroup"
						src="@/assets/img/BlueCard/lines.webp"
						alt="blueCard bg"
					/>
					<p class="lessonFormats__description">
						Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию, так и
						педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта подрастающему
						поколению и ко всем находят свой подход.
					</p>
					<button class="lessonFormats__btn ms_booking">записаться на тренировку</button>
				</div>
			</div>
			<div class="lessonFormats__individual">
				<button class="lessonFormats__lessonTitle" @click="showIndividual">
					<svg v-show="isShowIndividual" xmlns="http://www.w3.org/2000/svg" width="61" height="16" fill="none">
						<path
							fill="#fff"
							d="M60.707 8.707a1 1 0 0 0 0-1.414L54.343.929a1 1 0 1 0-1.414 1.414L58.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h60V7H0v2Z"
						/>
					</svg>
					индивидуальные тренировки
				</button>
				<div class="lessonFormats__description-toggler" v-show="isShowIndividual">
					<img
						class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--linesIndividual"
						src="@/assets/img/BlueCard/lines.webp"
						alt="blueCard bg"
					/>
					<p class="lessonFormats__description">
						Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию, так и
						педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта подрастающему
						поколению и ко всем находят свой подход.
					</p>
					<button class="lessonFormats__btn ms_booking">записаться на тренировку</button>
				</div>

				<router-link class="lessonFormats__link" :to="isShowIndividual ? '/individual' : '/group'"
					>читать больше
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
						<path
							fill="#FEFEFE"
							d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
						/>
					</svg>
				</router-link>
			</div>
		</div>
		<img class="thumb lessonFormats__thumb11" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb lessonFormats__thumb12" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb lessonFormats__thumb13" src="@/assets/img/thumbs2.svg" alt="thumb" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			isShowIndividual: true,
			isShowGroup: false,
		};
	},

	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},

	methods: {
		showGroup() {
			this.isShowGroup = true;
			this.isShowIndividual = false;
		},
		showIndividual() {
			this.isShowGroup = false;
			this.isShowIndividual = true;
		},
	},
};
</script>

<style lang="scss" scoped></style>
