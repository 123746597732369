<template>
  <section class="aboutPage">
    <div class="aboutPage__wrapper container">
      <div class="aboutPage__titleBlock">
        <h1 class="aboutPage__title">самокат-школа</h1>
        <h2 class="aboutPage__title aboutPage__title--grey">
          <svg v-if="screenWidth > 1686" width="230" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m186.927 0-14.82 14.822 17.365 17.366H0v20.96h189.472l-17.365 17.367 14.82 14.822 42.663-42.669L186.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="184"
            height="69"
            viewBox="0 0 184 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M149.741 0.609375L137.885 12.4667L151.777 26.3601L0.199219 26.3601V43.1281L151.777 43.1281L137.885 57.0215L149.741 68.8788L183.871 34.7441L149.741 0.609375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="93"
            height="35"
            viewBox="0 0 93 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75.3704 0.5L69.4425 6.42866L76.3883 13.3754L0.599609 13.3754V21.7593L76.3883 21.7593L69.4425 28.7061L75.3704 34.6347L92.4357 17.5674L75.3704 0.5Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            xmlns="http://www.w3.org/2000/svg"
            width="91"
            height="31"
            viewBox="0 0 91 31"
            fill="none"
          >
            <path
              d="M75.9635 0.413086L70.7906 5.58661L76.8518 11.6485L0 11.6485V18.9646L76.8518 18.9646L70.7906 25.0265L75.9635 30.2001L90.8552 15.3066L75.9635 0.413086Z"
              fill="#E6E6E6"
            />
          </svg>
          kss school
        </h2>
      </div>
      <div class="aboutPage__blueCard">
        <h3 class="aboutPage__blueCard-title">первые шаги</h3>
        <p class="aboutPage__blueCard-text">
          Самокат школа начала своё функционирование в 2012 году с нескольких
          небольших групп детей. Многие из выпускников не только до сих пор
          наслаждаются катанием, но и прямо сейчас стали
          тренерами-преподавателями. С тех пор поменялось практически все:
          Тренировочные площадки, самокаты, тенденции, трюки, количество
          желающих научиться кататься на экстремальном самокате.
        </p>
      </div>
      <div class="aboutPage__secondBlueCard">
        <div class="aboutPage__secondBlueCard-textBlock">
          <h3 class="aboutPage__secondBlueCard-title">
            Мы не просто учим кататься — мы учим любить кататься.
          </h3>
          <p class="aboutPage__blueCard-text aboutPage__blueCard-text--color">
            Самокат школа развивается и по сегодняшний день: группы для любого
            уровня катания и подготовки, удобное время для тренировок,
            бесплатные видео уроки от спортсменов высшей категории, эксклюзивные
            курсы, методики и способы добиться результата. Наша самокат школа —
            больше, чем просто школа.
          </p>
          <p
            class="aboutPage__blueCard-text aboutPage__blueCard-text--bold"
            v-if="screenWidth <= 430"
          >
            Наша самокат школа — больше, чем просто школа.
          </p>
        </div>
        <img
          v-if="screenWidth > 1686"
          src="@/assets/img/AboutPageTopCardImg.webp"
          alt="Blue boxes"
        />
        <img
          v-if="screenWidth <= 1686 && screenWidth >= 1356"
          src="@/assets/img/AboutPageTopCardImg125.webp"
          alt="Blue boxes"
        />
        <img
          v-if="screenWidth <= 1355 && screenWidth >= 640"
          src="@/assets/img/AboutPageTopCardImgTablet.webp"
          alt="Blue boxes"
        />
        <img
          v-if="screenWidth <= 430"
          src="@/assets/img/AboutPageTopCardImgMobile.webp"
          alt="Blue boxes"
        />
      </div>
      <div class="aboutPage__blackCard">
        <h3 class="aboutPage__blackCard-title">
          наши тренера действующие спортсмены, которые тренируются сами до сих
          пор, поэтому всегда в курсе всех новостей и веяний в самокатной
          индустрии.
          <br v-if="screenWidth <= 430" />
          <br v-if="screenWidth <= 430" />
          Мы считаем, что тренировки только с опытными и квалифицированными
          тренерами приведут
          <span class="aboutPage__blackCard-title--bg">
            <br v-if="screenWidth <= 1686" />
            к самым высоким результатам.
          </span>
        </h3>
      </div>
      <img
        class="thumb aboutPage__thumb1"
        src="@/assets/img/thumbs1.svg"
        alt="thumb"
      />
      <img
        class="thumb aboutPage__thumb2"
        src="@/assets/img/thumbs2.svg"
        alt="thumb"
      />
      <img
        class="thumb aboutPage__thumb3"
        src="@/assets/img/thumbs2.svg"
        alt="thumb"
      />
      <img
        v-if="screenWidth <= 430"
        class="thumb aboutPage__thumb4"
        src="@/assets/img/thumbs1.svg"
        alt="thumb"
      />
    </div>
  </section>
  <LineComponent />
  <section class="coaches">
    <div class="coaches__wrapper container">
      <div class="coaches__titleBlock">
        <h3 class="coaches__title">Тренерский</h3>
        <h3 class="coaches__title coaches__title--grey">
          <svg
            v-if="screenWidth > 1686"
            xmlns="http://www.w3.org/2000/svg"
            width="198"
            height="39"
            fill="none"
          >
            <path
              fill="#E6E6E6"
              d="m178.131.814-6.633 6.634 7.772 7.772H.08v9.38h179.19l-7.772 7.772 6.633 6.633 19.093-19.095L178.131.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="158"
            height="32"
            viewBox="0 0 158 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142.703 0.65625L137.397 5.96275L143.614 12.1805L0.261719 12.1805V19.6846L143.614 19.6846L137.397 25.9023L142.703 31.2088L157.977 15.9325L142.703 0.65625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="80"
            height="16"
            viewBox="0 0 80 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M71.8515 0.0273438L69.1986 2.68059L72.307 5.78945L0.630859 5.78945V9.54152L72.307 9.54152L69.1986 12.6504L71.8515 15.3036L79.4887 7.66548L71.8515 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="80" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M68.99 0 65.35 3.638l4.263 4.263H.5v5.145h69.114L65.35 17.31l3.638 3.638 10.473-10.473L68.989 0Z"
            />
          </svg>
          состав
        </h3>
      </div>
      <CoachesListSliderComponent
        v-if="screenWidth > 1686"
        :screenWidth="screenWidth"
      />
      <CoachesSliderTabletComponent
        v-if="screenWidth <= 1686 && screenWidth >= 640"
        :screenWidth="screenWidth"
      />
      <CoachesSliderMobileComponent
        v-if="screenWidth <= 430"
        :screenWidth="screenWidth"
      />
    </div>
  </section>
  <LineComponent />
  <section class="educationProgram container">
    <div class="educationProgram__wrapper">
      <div class="educationProgram__titleBlock">
        <h3 class="educationProgram__title">программа обучения</h3>
        <h3 class="educationProgram__title educationProgram__title--grey">
          <svg v-if="screenWidth > 1686" width="345" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m325.797.814-6.632 6.634 7.771 7.772H0v9.38h326.936l-7.771 7.772 6.632 6.633L344.89 19.91 325.797.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="276"
            height="31"
            viewBox="0 0 276 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M260.638 0.046875L255.332 5.35337L261.549 11.5711L0 11.5711V19.0752L261.549 19.0752L255.332 25.2929L260.638 30.5994L275.912 15.3232L260.638 0.046875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="138"
            height="16"
            viewBox="0 0 138 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M130.319 0.226562L127.666 2.87981L130.774 5.98867L0 5.98867V9.74074L130.774 9.74074L127.666 12.8496L130.319 15.5028L137.956 7.8647L130.319 0.226562Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="21"
            viewBox="0 0 34 21"
            fill="none"
          >
            <path
              d="M23.4891 -0.00292969L19.8514 3.6353L24.1138 7.89828L0 7.89828V13.0433L24.1138 13.0433L19.8514 17.3062L23.4891 20.9445L33.9616 10.4708L23.4891 -0.00292969Z"
              fill="#E6E6E6"
            />
          </svg>
          три этапа:
        </h3>
      </div>
      <div class="educationProgram__addInfoBlock" v-if="screenWidth > 640">
        <svg
          v-if="screenWidth <= 1686 && screenWidth >= 640"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13.6672" cy="13.7648" r="12.8703" fill="#70C1FF" />
          <path
            d="M14.9041 16.2993H11.1817L13.0561 4.89453H18.0721L14.9041 16.2993ZM11.8945 22.8201C11.2433 22.8201 10.6977 22.6001 10.2577 22.1601C9.81766 21.7025 9.59766 21.1569 9.59766 20.5233C9.59766 19.7665 9.87046 19.1153 10.4161 18.5697C10.9617 18.0241 11.6041 17.7513 12.3433 17.7513C12.9945 17.7513 13.5401 17.9801 13.9801 18.4377C14.4201 18.8953 14.6401 19.4497 14.6401 20.1009C14.6401 20.8401 14.3673 21.4825 13.8217 22.0281C13.2761 22.5561 12.6337 22.8201 11.8945 22.8201Z"
            fill="white"
          />
        </svg>
        <h3 class="educationProgram__addInfoBlock-title">
          Программа обучения составлена действующими спортсменами, в
          соответствии с международными стандартами и актуальным уровнем
          катания.
        </h3>
      </div>
    </div>
    <EducationStepCardComponent :screenWidth="screenWidth" />
  </section>
  <LineComponent />
  <section class="lessonFormats">
    <div class="lessonFormats__wrapper container">
      <div class="lessonFormats__titleBlock">
        <h3 class="lessonFormats__title">форматы занятий</h3>
        <h3 class="lessonFormats__title lessonFormats__title--grey">
          <svg
            v-if="screenWidth > 1686"
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="39"
            fill="none"
          >
            <path
              fill="#E6E6E6"
              d="m84.052.814-6.633 6.634 7.771 7.772H0v9.38h85.19l-7.77 7.772 6.632 6.633 19.093-19.095L84.052.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="83"
            height="32"
            viewBox="0 0 83 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.4404 0.648438L62.1346 5.95493L68.3516 12.1726L0.199219 12.1726V19.6768L68.3516 19.6768L62.1346 25.8945L67.4404 31.201L82.7149 15.9247L67.4404 0.648438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.2202 0.0273438L31.5673 2.68059L34.6758 5.78945L0.599609 5.78945V9.54152L34.6758 9.54152L31.5673 12.6504L34.2202 15.3036L41.8574 7.66548L34.2202 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="27" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M16.49-.003 12.85 3.635l4.263 4.263H.5v5.145h16.614l-4.263 4.263 3.638 3.639L26.962 10.47 16.489-.003Z"
            />
          </svg>
          в самокат-школе KSS
        </h3>
      </div>
      <LessonFormatCardComponent v-if="screenWidth > 1686" />
      <LessonFormatCardTabletComponent
        v-if="screenWidth <= 1686 && screenWidth >= 640"
      />
      <LessonFormatCardMobileComponent v-if="screenWidth <= 430" />
    </div>
  </section>
  <LineComponent />
  <section class="federationMembership container">
    <div class="federationMembership__wrapper">
      <div class="federationMembership__leftSide">
        <div class="federationMembership__titleBlock">
          <h3 class="federationMembership__title">членство в федерации</h3>
          <h3
            class="federationMembership__title federationMembership__title--blue"
          >
            <svg v-if="screenWidth > 1686" width="85" height="39" fill="none">
              <path
                fill="#70C1FF"
                d="m65.797.814-6.632 6.634 7.771 7.772H0v9.38h66.936l-7.771 7.772 6.632 6.633L84.89 19.91 65.797.814Z"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1686 && screenWidth >= 1356"
              width="69"
              height="31"
              viewBox="0 0 69 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M52.837 0.257812L47.5312 5.56431L53.7481 11.782L0.199219 11.782V19.2862L53.7481 19.2862L47.5312 25.5039L52.837 30.8104L68.1115 15.5341L52.837 0.257812Z"
                fill="#70C1FF"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1355 && screenWidth >= 640"
              width="35"
              height="17"
              viewBox="0 0 35 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.9185 0.824219L24.2656 3.47747L27.3741 6.58632L0.599609 6.58632V10.3384L27.3741 10.3384L24.2656 13.4472L26.9185 16.1005L34.5557 8.46236L26.9185 0.824219Z"
                fill="#70C1FF"
              />
            </svg>
            <svg
              v-if="screenWidth <= 430"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="14"
              viewBox="0 0 28 14"
              fill="none"
            >
              <path
                d="M21.2166 0.81778L19.078 2.95665L21.5838 5.46281L0 5.46281V8.48748L21.5838 8.48748L19.078 10.9936L21.2166 13.1325L27.3732 6.97515L21.2166 0.81778Z"
                fill="#70C1FF"
              />
            </svg>
            самокатного спорта россии
          </h3>
        </div>
        <p class="federationMembership__text">
          Самокат-школа KSS SCHOOL является членом Региональной общественной
          организации «Федерация самокатного спорта России»
        </p>
        <router-link
          v-if="screenWidth > 640"
          to="/scooter-sport"
          class="federationMembership__btn"
        >
          о самокатном спорте
          <svg v-if="screenWidth > 1686" width="36" height="16" fill="none">
            <path
              fill="#fff"
              d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 640"
            width="15"
            height="7"
            viewBox="0 0 15 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8836 3.38441C15.0398 3.2282 15.0398 2.97493 14.8836 2.81872L12.338 0.273135C12.1818 0.116926 11.9286 0.116926 11.7724 0.273135C11.6161 0.429345 11.6161 0.682611 11.7724 0.838821L14.0351 3.10156L11.7724 5.3643C11.6161 5.52051 11.6161 5.77378 11.7724 5.92999C11.9286 6.0862 12.1818 6.0862 12.338 5.92999L14.8836 3.38441ZM0.800781 3.50156H14.6008V2.70156H0.800781V3.50156Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
      <img
        class="federationMembership__certificate"
        src="@/assets/img/certificate.png"
        alt="Свидетельство"
      />
      <router-link
        v-if="screenWidth <= 430"
        to="/scooter-sport"
        class="federationMembership__btn"
      >
        о самокатном спорте
        <svg
          width="25"
          height="18"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
            fill="#70C1FF"
          />
        </svg>
      </router-link>

      <img
        v-if="screenWidth <= 430"
        class="federationMembership__bg"
        src="@/assets/img/membershipBGMobile.svg"
        alt="bg"
      />

      <img
        class="thumb federationMembership__thumb4"
        src="@/assets/img/thumbs1.svg"
        alt="thumb"
      />
      <img
        v-if="screenWidth <= 1686 && screenWidth >= 640"
        class="thumb federationMembership__thumb5"
        src="@/assets/img/thumbs2.svg"
        alt="thumb"
      />
      <img
        class="thumb federationMembership__thumb6"
        src="@/assets/img/thumbs2.svg"
        alt="thumb"
      />
    </div>
  </section>
  <LineComponent />
  <section class="events">
    <div class="events__wrapper container">
      <div class="events__titleBlock">
        <div class="events__titleBlock-wrapper">
          <h3 class="events__title">мероприятия</h3>
          <h3 class="events__title events__title--grey">
            <svg v-if="screenWidth > 1686" width="161" height="39" fill="none">
              <path
                fill="#E6E6E6"
                d="m141.797.814-6.632 6.634 7.771 7.772H0v9.38h142.936l-7.771 7.772 6.632 6.633L160.89 19.91 141.797.814Z"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1686 && screenWidth >= 1356"
              width="130"
              height="32"
              viewBox="0 0 130 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M113.836 0.65625L108.53 5.96275L114.747 12.1805L0.398438 12.1805V19.6846L114.747 19.6846L108.53 25.9023L113.836 31.2088L129.111 15.9325L113.836 0.65625Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1355 && screenWidth >= 640"
              width="65"
              height="16"
              viewBox="0 0 65 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.9181 0.0273438L54.2652 2.68059L57.3737 5.78945L0.199219 5.78945V9.54152L57.3737 9.54152L54.2652 12.6504L56.9181 15.3036L64.5553 7.66548L56.9181 0.0273438Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg
              v-if="screenWidth <= 430"
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="21"
              viewBox="0 0 27 21"
              fill="none"
            >
              <path
                d="M15.9891 0L12.3514 3.63823L16.6138 7.90121L0 7.90121V13.0462L16.6138 13.0462L12.3514 17.3092L15.9891 20.9474L26.4616 10.4737L15.9891 0Z"
                fill="#E6E6E6"
              />
            </svg>
            kss school
          </h3>
        </div>
        <router-link v-if="screenWidth > 640" to="/events" class="events__btn">
          читать больше
          <svg v-if="screenWidth > 1686" width="36" height="16" fill="none">
            <path
              fill="#fff"
              d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="29"
            height="13"
            viewBox="0 0 29 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.1657 7.16725C28.4781 6.85483 28.4781 6.3483 28.1657 6.03588L23.0745 0.944708C22.7621 0.632289 22.2556 0.632289 21.9431 0.944708C21.6307 1.25713 21.6307 1.76366 21.9431 2.07608L26.4686 6.60156L21.9431 11.127C21.6307 11.4395 21.6307 11.946 21.9431 12.2584C22.2556 12.5708 22.7621 12.5708 23.0745 12.2584L28.1657 7.16725ZM0 7.40156H27.6V5.80156H0V7.40156Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="15"
            height="7"
            viewBox="0 0 15 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0828 3.78284C14.2391 3.62663 14.2391 3.37337 14.0828 3.21716L11.5373 0.671573C11.381 0.515363 11.1278 0.515363 10.9716 0.671573C10.8154 0.827783 10.8154 1.08105 10.9716 1.23726L13.2343 3.5L10.9716 5.76274C10.8154 5.91895 10.8154 6.17222 10.9716 6.32843C11.1278 6.48464 11.381 6.48464 11.5373 6.32843L14.0828 3.78284ZM0 3.9H13.8V3.1H0V3.9Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
      <div class="events__cardsList">
        <EventCardComponent
          v-for="event in events.splice(0, 4)"
          :key="event.id"
          :event="event"
          :screenWidth="screenWidth"
          v-if="screenWidth > 640"
        />
        <EventSliderComponent
          :events="events"
          :screenWidth="screenWidth"
          v-if="screenWidth <= 430"
        />
      </div>
    </div>
  </section>
  <LineComponent />
  <section class="partners">
    <div class="partners__wrapper container">
      <div class="partners__titleBlock">
        <h3 class="partners__title">партнёры</h3>
        <h3 class="partners__title partners__title--grey">
          <svg
            v-if="screenWidth > 1686"
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="39"
            fill="none"
          >
            <path
              fill="#E6E6E6"
              d="M41.051.814 34.42 7.448l7.771 7.772H.5v9.38h41.69l-7.77 7.772 6.631 6.633L60.145 19.91 41.05.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="49"
            height="32"
            viewBox="0 0 49 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.0408 0.65625L27.735 5.96275L33.952 12.1805L0.599609 12.1805V19.6846L33.952 19.6846L27.735 25.9023L33.0408 31.2088L48.3153 15.9325L33.0408 0.65625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="33" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M22.49 0 18.85 3.638l4.263 4.263H0v5.145h23.114L18.85 17.31l3.638 3.638 10.473-10.473L22.489 0Z"
            />
          </svg>
          самокат-школы
        </h3>
      </div>
    </div>
    <PartnersComponent v-if="screenWidth > 1686" />
    <PartnersSliderTabletComponent
      v-if="screenWidth <= 1686 && screenWidth >= 640"
    />
    <PartnersSliderMobileComponent v-if="screenWidth <= 430" />
  </section>
  <LineComponent />
  <section class="feedback">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg
            v-if="screenWidth > 1686"
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="39"
            fill="none"
          >
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="45"
            height="31"
            viewBox="0 0 45 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7044 0.25L24.3986 5.5565L30.6155 11.7742L0 11.7742V19.2783L30.6155 19.2783L24.3986 25.4961L29.7044 30.8026L44.9788 15.5263L29.7044 0.25Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
  <LineComponent />
  <section class="questions container">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #57606f" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
</template>

<script>
import CoachesListSliderComponent from "@/components/CoachesListSliderComponent.vue";
import CoachesSliderMobileComponent from "@/components/CoachesSliderMobileComponent.vue";
import CoachesSliderTabletComponent from "@/components/CoachesSliderTabletComponent.vue";
import EducationStepCardComponent from "@/components/EducationStepCardComponent.vue";
import EventCardComponent from "@/components/EventCardComponent.vue";
import EventSliderComponent from "@/components/EventSliderComponent.vue";
import FeedbackSliderComponent from "@/components/FeedbackSliderComponent.vue";
import LessonFormatCardComponent from "@/components/LessonFormatCardComponent.vue";
import LessonFormatCardMobileComponent from "@/components/LessonFormatCardMobileComponent.vue";
import LessonFormatCardTabletComponent from "@/components/LessonFormatCardTabletComponent.vue";
import LineComponent from "@/components/LineComponent.vue";
import PartnersComponent from "@/components/PartnersComponent.vue";
import PartnersSliderMobileComponent from "@/components/PartnersSliderMobileComponent.vue";
import PartnersSliderTabletComponent from "@/components/PartnersSliderTabletComponent.vue";
import QuestionComponent from "@/components/QuestionComponent.vue";

import { mapState } from "vuex";

export default {
  components: {
    CoachesListSliderComponent,
    LineComponent,
    EducationStepCardComponent,
    EventSliderComponent,
    LessonFormatCardComponent,
    EventCardComponent,
    PartnersComponent,
    FeedbackSliderComponent,
    QuestionComponent,
    CoachesSliderMobileComponent,
    LessonFormatCardMobileComponent,
    LessonFormatCardTabletComponent,
    PartnersSliderMobileComponent,
    PartnersSliderTabletComponent,
    CoachesSliderTabletComponent,
  },
  props: {
    screenWidth: Number,
  },
  computed: {
    ...mapState({
      events: (state) => state.eventCards.cards,
      coaches: (state) => state.coaches.coaches,
    }),
  },
  mounted() {
    this.$store.dispatch("eventsData");
    this.$store.dispatch("fetchCoaches");
  },
};
</script>

<style lang="scss" scoped></style>
