const breadcrumbs = {
	namespaced: true,
	state: {
		links: [
			{
				id: 1,
				title: 'ГРУППОВЫЕ ТРЕНИРОВКИ',
				url: '/services-and-price/group',
				isOpen: false,
				isClose: true,
			},
			{
				id: 2,
				title: 'ИНДИВИДУАЛЬНЫЕ ТРЕНИРОВКИ',
				url: '/services-and-price/individual',
				isOpen: false,
				isClose: true,
			},
			{
				id: 3,
				title: 'АРЕНДА СКЕЙТ-ПАРКА',
				url: '/services-and-price/park',
				isOpen: false,
				isClose: true,
			},
			{
				id: 4,
				title: 'ПРОКАТ',
				url: '/services-and-price/rental',
				isOpen: false,
				isClose: true,
			},
			{
				id: 5,
				title: 'СВОБОДНОЕ КАТАНИЕ',
				url: '/services-and-price/free-driving',
				isOpen: false,
				isClose: true,
			},
			{
				id: 6,
				title: 'ОРГАНИЗАЦИЯ МЕРОПРИЯТИЙ',
				url: '/services-and-price/events',
				isOpen: false,
				isClose: true,
			},
		],
	},
	getters: {
		getLinks(state) {
			return state.links;
		},
	},
	mutations: {},
	actions: {},
};

export default breadcrumbs;
