<template>
  <section class="progressDiaryPage">
    <div class="progressDiaryPage__wrapper container">
      <div class="progressDiaryPage__titleBlock">
        <h1 class="progressDiaryPage__title">дневник прогресса 2022-2023</h1>
        <h2 class="progressDiaryPage__title progressDiaryPage__title--grey">
          <svg v-if="screenWidth > 1686" width="289" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m269.797.814-6.632 6.634 7.771 7.772H0v9.38h270.936l-7.771 7.772 6.632 6.633L288.89 19.91 269.797.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="232"
            height="32"
            viewBox="0 0 232 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M216.639 0.648438L211.333 5.95493L217.55 12.1726L0.800781 12.1726V19.6768L217.55 19.6768L211.333 25.8945L216.639 31.201L231.913 15.9247L216.639 0.648438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="116"
            height="16"
            viewBox="0 0 116 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M108.319 0.324219L105.666 2.97747L108.775 6.08632L0.400391 6.08632V9.83839L108.775 9.83839L105.666 12.9472L108.319 15.6005L115.957 7.96236L108.319 0.324219Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="39.265" height="15.836" fill="none">
            <path
              fill="#E6E6E6"
              d="m31.348 0-2.75 2.75 3.222 3.223H0v3.89h31.82l-3.222 3.223 2.75 2.75 7.917-7.918L31.348 0Z"
            />
          </svg>
          12 мая 2023
        </h2>
        <img
          v-if="screenWidth > 640"
          class="progressDiaryPage__img"
          src="@/assets/img/diaryPageImage.webp"
          alt="Progress diary"
        />
        <img
          v-if="screenWidth <= 430"
          class="progressDiaryPage__img"
          src="@/assets/img/Events/mainEventMobile.webp"
          alt="Progress diary"
        />
        <p class="progressDiaryPage__text">
          На прошедших выходных ученики KSS SCHOOL | Самокат школа начали сдавать «Весенний зачет 2023» Ребята сдают
          нормативы и показывают всё, чему они научились за последнее полгода. И даже ученики, впервые переступившие
          порог нашей самокат школы совсем недавно, уже демонстрируют результат тренировок.Впереди зачеты для еще
          нескольких групп.
        </p>
        <span class="progressDiaryPage__goodbye">До встречи на тренировках!</span>
      </div>
      <div v-if="screenWidth > 640" class="progressDiaryPage__events">
        <h2 class="progressDiaryPage__events-title">другие события самокат-школы</h2>
        <svg v-if="screenWidth > 1686" width="319" height="39" fill="none">
          <path
            fill="#E6E6E6"
            d="m299.797 0-6.632 6.633 7.771 7.772H0v9.38h300.936l-7.771 7.773 6.632 6.633 19.093-19.096L299.797 0Z"
          />
        </svg>
        <svg
          v-if="screenWidth >= 1356 && screenWidth <= 1686"
          width="256"
          height="31"
          viewBox="0 0 256 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M240.639 0.210938L235.333 5.51743L241.55 11.7351L0.800781 11.7351V19.2393L241.55 19.2393L235.333 25.457L240.639 30.7635L255.913 15.4872L240.639 0.210938Z"
            fill="#E6E6E6"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth <= 1355"
          width="128"
          height="16"
          viewBox="0 0 128 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M120.319 0.597656L117.666 3.2509L120.775 6.35976L0.400391 6.35976V10.1118L120.775 10.1118L117.666 13.2207L120.319 15.8739L127.957 8.2358L120.319 0.597656Z"
            fill="#E6E6E6"
          />
        </svg>
        <div class="progressDiaryPage__events-block" v-for="event in diaryPageEvents" :key="event.id">
          <EventCardComponent :event="event" :screenWidth="screenWidth" />
        </div>
      </div>
    </div>
  </section>
  <LineComponent />
  <section class="events" v-if="screenWidth <= 430">
    <div class="events__wrapper container">
      <div class="events__titleBlock">
        <div class="events__titleBlock-wrapper">
          <h3 class="events__title">мероприятия</h3>
          <h3 class="events__title events__title--grey">
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="21" viewBox="0 0 27 21" fill="none">
              <path
                d="M15.9891 0L12.3514 3.63823L16.6138 7.90121L0 7.90121V13.0462L16.6138 13.0462L12.3514 17.3092L15.9891 20.9474L26.4616 10.4737L15.9891 0Z"
                fill="#E6E6E6"
              />
            </svg>

            kss school
          </h3>
        </div>
      </div>
      <div class="events__cardsList">
        <EventSliderComponent :events="events" :screenWidth="screenWidth" />
      </div>
    </div>
  </section>
  <LineComponent v-if="screenWidth <= 430" />
  <section class="progressDiaryPage__slider">
    <div
      class="progressDiaryPage__titleBlock progressDiaryPage__titleBlockMobile progressDiaryPage__titleBlock--width container"
    >
      <h1 class="progressDiaryPage__title">мероприятия</h1>
      <h2 class="progressDiaryPage__title progressDiaryPage__title--grey">
        <svg v-if="screenWidth > 1686" width="213" height="39" fill="none">
          <path
            fill="#E6E6E6"
            d="m193.797.814-6.632 6.634 7.771 7.772H0v9.38h194.936l-7.771 7.772 6.632 6.633L212.89 19.91 193.797.814Z"
          />
        </svg>
        <svg
          v-if="screenWidth >= 1356 && screenWidth <= 1686"
          width="172"
          height="32"
          viewBox="0 0 172 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M155.839 0.460938L150.533 5.76743L156.75 11.9851L0.800781 11.9851V19.4893L156.75 19.4893L150.533 25.707L155.839 31.0135L171.113 15.7372L155.839 0.460938Z"
            fill="#E6E6E6"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth <= 1355"
          width="86"
          height="16"
          viewBox="0 0 86 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M77.9193 0.722656L75.2664 3.3759L78.3748 6.48476L0.400391 6.48476V10.2368L78.3748 10.2368L75.2664 13.3457L77.9193 15.9989L85.5565 8.3608L77.9193 0.722656Z"
            fill="#E6E6E6"
          />
        </svg>
        медиа
      </h2>
    </div>
    <DiarySliderComponent :screenWidth="screenWidth" />
  </section>
  <LineComponent v-if="screenWidth <= 430" style="margin-bottom: 80px" />
</template>

<script>
import DiarySliderComponent from '@/components/DiarySliderComponent.vue';
import EventCardComponent from '@/components/EventCardComponent.vue';
import EventSliderComponent from '@/components/EventSliderComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import { mapState } from 'vuex';

export default {
  name: 'Events Page',
  components: { LineComponent, EventCardComponent, DiarySliderComponent, EventSliderComponent },

  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      diaryPageEvents: (state) => state.eventCards.diaryPageEvents,
      events: (state) => state.eventCards.cards,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
