<template>
	<div v-if="screenWidth > 640" class="schemePark__numbered-description-wrapper container">
		<div class="column1">
			<article class="schemePark__numbered-description" v-for="item in scheme" :key="item.id">
				<div class="schemePark__numbered-description-number">{{ item.id }}</div>
				<div class="schemePark__numbered-description-subWrapper">
					<h3 class="schemePark__numbered-description-title">{{ item.title }}</h3>
					<p v-if="item.text !== ''" class="schemePark__numbered-description-text">{{ item.text }}</p>
				</div>
			</article>
		</div>
		<div class="column2">
			<article class="schemePark__numbered-description" v-for="item in scheme2" :key="item.id">
				<div class="schemePark__numbered-description-number">{{ item.id }}</div>
				<div class="schemePark__numbered-description-subWrapper">
					<h3 class="schemePark__numbered-description-title">{{ item.title }}</h3>
					<p v-if="item.text !== ''" class="schemePark__numbered-description-text">{{ item.text }}</p>
				</div>
			</article>
		</div>
		<div class="column3">
			<article class="schemePark__numbered-description" v-for="item in scheme3" :key="item.id">
				<div class="schemePark__numbered-description-number">{{ item.id }}</div>
				<div class="schemePark__numbered-description-subWrapper">
					<h3 class="schemePark__numbered-description-title">{{ item.title }}</h3>
					<p v-if="item.text !== ''" class="schemePark__numbered-description-text">{{ item.text }}</p>
				</div>
			</article>
		</div>
	</div>

	<div v-if="screenWidth <= 430" class="schemePark__numbered-description-wrapper container">
		<button class="schemePark__numbered-description-btn" @click="showMore">
			фигуры подробнее
			<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="isClose">
				<path
					d="M17.3105 11.1422L14.3039 8.21005L10.781 11.6458L10.781 0L6.52912 -1.8585e-07L6.52912 11.6458L3.00618 8.21005L-0.000477324 11.1422L8.65503 19.5834L17.3105 11.1422Z"
					fill="white"
				/>
			</svg>

			<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="isOpen">
    <path
        d="M17.3105 8.8578L14.3039 11.7899L10.781 8.3542L10.781 20L6.52912 20L6.52912 8.3542L3.00618 11.7899 0.000477324 8.8578L8.65503 0.4166L17.3105 8.8578Z"
        fill="white"
    />
</svg>
		</button>
		<article v-show="isShow" class="schemePark__numbered-description" v-for="item in schemeMobile" :key="item.id">
			<div class="schemePark__numbered-description-number">{{ item.id }}</div>
			<div>
				<h3 class="schemePark__numbered-description-title">{{ item.title }}</h3>
				<p class="schemePark__numbered-description-text">{{ item.text }}</p>
			</div>
		</article>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		screenWidth: Number,
	},
	data() {
		return {
			isShow: false,
			isOpen: false,
			isClose: true,
		};
	},
	computed: {
		...mapState({
			scheme: (state) => state.schemePark.scheme,
			scheme2: (state) => state.schemePark.scheme2,
			scheme3: (state) => state.schemePark.scheme3,
			schemeMobile: (state) => state.schemePark.schemeMobile,
		}),
	},

	methods: {
		showMore() {
			this.isShow = !this.isShow;
			this.isOpen = !this.isOpen;
			this.isClose = !this.isClose;
		},
	},
};
</script>

<style lang="scss" scoped></style>
