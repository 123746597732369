<template>
	<ul class="price-list">
		<li class="price-details" v-for="item in price" :key="item.id">
			<svg class="price-tippy" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.05218 6.23866H4.76994V4.08538H4.05218V6.23866ZM4.41106 3.36762C4.51274 3.36762 4.59804 3.33317 4.66694 3.26427C4.73585 3.19536 4.77018 3.11019 4.76994 3.00874C4.76994 2.90706 4.73549 2.82177 4.66658 2.75286C4.59768 2.68396 4.5125 2.64963 4.41106 2.64987C4.30938 2.64987 4.22408 2.68432 4.15518 2.75322C4.08627 2.82213 4.05194 2.9073 4.05218 3.00874C4.05218 3.11043 4.08663 3.19572 4.15554 3.26463C4.22444 3.33353 4.30962 3.36786 4.41106 3.36762ZM4.41106 8.03306C3.91461 8.03306 3.44807 7.93879 3.01143 7.75026C2.57479 7.56173 2.19498 7.30609 1.87199 6.98333C1.549 6.66034 1.29335 6.28053 1.10506 5.84389C0.916771 5.40726 0.822505 4.94071 0.822266 4.44426C0.822266 3.94781 0.916531 3.48127 1.10506 3.04463C1.29359 2.608 1.54924 2.22818 1.87199 1.90519C2.19498 1.5822 2.57479 1.32656 3.01143 1.13827C3.44807 0.949974 3.91461 0.855708 4.41106 0.855469C4.90751 0.855469 5.37405 0.949734 5.81069 1.13827C6.24733 1.3268 6.62714 1.58244 6.95013 1.90519C7.27312 2.22818 7.52888 2.608 7.71741 3.04463C7.90595 3.48127 8.00009 3.94781 7.99985 4.44426C7.99985 4.94071 7.90559 5.40726 7.71706 5.84389C7.52852 6.28053 7.27288 6.66034 6.95013 6.98333C6.62714 7.30633 6.24733 7.56209 5.81069 7.75062C5.37405 7.93915 4.90751 8.0333 4.41106 8.03306Z"
					fill="#0091FF"
				/>
			</svg>
			<span class="price-title">{{ item.title }}</span>
			<div class="price-people" v-html="item.people"></div>
			<span class="price-quantity">{{ item.quantity }}</span>
			<span class="price-duration">{{ item.duration }}</span>
			<span class="price-currentPrice">{{ item.currentPrice }} ₽</span>
			<button class="price-btn ms_booking">записаться</button>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		price: Array,
	},

	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},
};
</script>

<style lang="scss" scoped></style>
