import { v4 as uuidv4 } from 'uuid';

const faq = {
  state: {
    questions: [
      {
        id: uuidv4(),
        title: 'ЧТО ВЗЯТЬ НА ПЕРВУЮ ТРЕНИРОВКУ?',
        answer: `
				<ol class="faq__question-answer-list">
					<li>
						Справка от терапевта об отсутствии ограничений <br />
						для занятия экстремальным спортом.
					</li>
					<li>
						<div>
							Расписка от родителей о том, что они и ученик ознакомлены <br />
							с правилами посещения скейт-парка:
							<div class="faq__question-answer-list-buttons">
								<a href="/rules">Правила парка</a>
								<a href="/child-receipt">Расписка для детей</a>
								<a href="/parents-receipt">Расписка для родителей</a>
							</div>
						</div>
					</li>
					<li>Копия свидетельства о рождении.</li>
					<li>
						Одежда должна быть удобна для катания: спортивные <br />
						штаны или шорты, кеды на плоской подошве.
					</li>
					<li>
						На каждую тренировку необходимо взять с собой свой трюковой самокат, <br />
						защиту (шлем, наколенники, налокотники), бутылку воды.
					</li>
				</ol>
				`,
      },
      {
        id: uuidv4(),
        title: 'ЕСТЬ ЛИ ОГРАНИЧЕНИЯ ПО СОСТОЯНИЮ ЗДОРОВЬЯ?',
        answer: `
				<p class="faq__question-answer-singleText">
						Трюковой самокат- экстремальный  вид спорта. Поэтому к занятиям мы требуем подходить ответственно - получая справку об отсутствии ограничений по здоровью.		
				</p>
				`,
      },
      {
        id: uuidv4(),
        title: 'С КАКОГО ВОЗРАСТА МОЖНО ЗАНИМАТЬСЯ?',
        answer: `
				<p class="faq__question-answer-singleText">
						Занятия можно начинать с 6 лет.		
				</p>
				`,
      },
      {
        id: uuidv4(),
        title: 'Я СМОГУ УЧАСТВОВАТЬ В СОРЕВНОВАНИЯХ?',
        answer: `
				<p class="faq__question-answer-singleText">
						Раз в полгода в самокат-школе проходит зачет среди всех групп подготовки. По итогам зачета ученики отбираются на внутренние соревнования школы.
				</p>
				`,
      },
      {
        id: uuidv4(),
        title: 'ВЫ КАТАЕТЕСЬ НА ЭЛЕКТРОСАМОКАТАХ?',
        answer: `
				<p class="faq__question-answer-singleText">
						Наш профиль - трюковой самокат. Мы можем обучить базовому владению самокатом.
				</p>
				`,
      },
      {
        id: uuidv4(),
        title: 'КАК ОПРЕДЕЛИТЬ УРОВЕНЬ КАТАНИЯ?',
        answer: `
				<ol class="faq__question-answer-list">
				<h4 class="faq__question-answer-list-title">Мы сформировали определенные критерии:</h4>
					<li>
						подготовительная группа (первые шаги в самокатном спорте, изучение базовых элементов, правил нахождения в скейт-парке, техника разгона и торможения)
					</li>
					<li>
						начинающая группа (изучение базовых трюков - барспин, вип, хил, 360, первичные техники баланса); 
					</li>
					<li>
						продолжающая группа  (изучение двойных трюков, трансферы, подготовка к соревнованиям).
					</li>
				</ol>
				`,
      },
      {
        id: uuidv4(),
        title: 'ЗАПИСЫВАТЬСЯ ОБЯЗАТЕЛЬНО?',
        answer: `
				<p class="faq__question-answer-singleText">
						Запись на тренировки обязательна. Мы хотим, чтобы в группах было комфортное количество человек, и каждому ученику уделялось должное количество времени и внимания.
				</p>
				`,
      },
      {
        id: uuidv4(),
        title: 'СКОЛЬКО РАЗ В НЕДЕЛЮ МОЖНО ПОСЕЩАТЬ ЗАНЯТИЯ?',
        answer: `
				<p class="faq__question-answer-singleText">
						Занятия можно посещать неограниченное количество раз по предварительной записи. Группа должна соответствовать Вашему уровню подготовки. 
				</p>
				`,
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default faq;
