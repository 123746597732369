<template>
	<div class="partners__logosList">
		<div class="partners__logosList-wrapper container">
			<img
				v-tippy="partner.title"
				v-for="partner in partners"
				:key="partner.id"
				:src="partner.img"
				:alt="partner.title"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	computed: {
		...mapState({
			partners: (state) => state.partners.partners,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
