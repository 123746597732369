<template>
  <section class="aboutScooterPage">
    <div class="aboutScooterPage__wrapper container">
      <div class="aboutScooterPage__titleBlock">
        <h1 class="aboutScooterPage__title">Самокатный</h1>
        <h2 class="aboutScooterPage__title aboutScooterPage__title--grey">
          <svg v-if="screenWidth > 1686" width="230" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m186.927 0-14.82 14.822 17.365 17.366H0v20.96h189.472l-17.365 17.367 14.82 14.822 42.663-42.669L186.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="184"
            height="69"
            viewBox="0 0 184 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M149.741 0.585938L137.885 12.4433L151.777 26.3367L0.199219 26.3367V43.1046L151.777 43.1046L137.885 56.9981L149.741 68.8554L183.871 34.7207L149.741 0.585938Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="93"
            height="35"
            viewBox="0 0 93 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75.3704 0.796875L69.4425 6.72554L76.3883 13.6723L0.599609 13.6723V22.0562L76.3883 22.0562L69.4425 29.0029L75.3704 34.9316L92.4357 17.8642L75.3704 0.796875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            xmlns="http://www.w3.org/2000/svg"
            width="161"
            height="34"
            viewBox="0 0 161 34"
            fill="none"
          >
            <path
              d="M143.934 0.583984L138.131 6.3878L144.931 13.1882L0.380859 13.1882V21.3956L144.931 21.3956L138.131 28.1961L143.934 33.9999L160.64 17.2919L143.934 0.583984Z"
              fill="#E6E6E6"
            />
          </svg>
          спорт
        </h2>
      </div>
      <div class="aboutScooterPage__blueCard">
        <h3 class="aboutScooterPage__blueCard-title">
          Самокатный спорт или же кикскутеринг (англ. scootering, freestyle scootering) —
        </h3>
        <p class="aboutScooterPage__blueCard-text">
          экстремальный вид спорта, заключающийся в катании и исполнении трюков на специальном трюковом самокате.
          Человека, занимающегося самокатным спортом, называют самокатером (англ. scooter rider), или просто райдером.
          На данный момент, кикскутеринг не является олимпийским спортом.
        </p>
      </div>
      <img
        class="aboutScooterPage__img"
        v-if="screenWidth > 640"
        src="@/assets/img/AboutScooterTop.webp"
        alt="Scooter top img"
      />
      <div class="aboutScooterPage__blackCard">
        <h3 class="aboutScooterPage__blackCard-title">
          Имеет широкое распространение по всему миру, существуют профессиональные команды, проводятся соревнования и
          другие мероприятия. Наиболее распространён среди подростков, образует субкультуру самокатеров.
        </h3>
      </div>
    </div>
    <img
      v-if="screenWidth <= 430"
      src="@/assets/img/AboutScooterTopMobile.webp"
      alt="Scooter top img"
      class="aboutScooterPage__maimImg"
    />

    <img v-if="screenWidth <= 430" class="thumb aboutScooterPage__thumb1" src="@/assets/img/thumbs1.svg" alt="thumb" />
    <img v-if="screenWidth <= 430" class="thumb aboutScooterPage__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
    <img v-if="screenWidth <= 430" class="thumb aboutScooterPage__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
  </section>

  <TickerComponent />
  <LineComponent v-if="screenWidth <= 430" style="width: 336px; margin-top: 35px" />

  <section class="scooterHistory">
    <div class="scooterHistory__wrapper container">
      <div class="scooterHistory__leftBlock">
        <div class="scooterHistory__leftBlock--padding">
          <div class="scooterHistory__leftTitleBlock">
            <h2 class="scooterHistory__leftTitleBlock-title">История</h2>
            <h2 class="scooterHistory__leftTitleBlock-title scooterHistory__leftTitleBlock-title--blue">
              <svg v-if="screenWidth > 1686" width="56" height="39" fill="none">
                <path
                  fill="#70C1FF"
                  d="m36.983 0-6.606 6.774 7.74 7.936H0v9.58h38.117l-7.74 7.936L36.983 39 56 19.5 36.983 0Z"
                />
              </svg>
              <svg
                v-if="screenWidth >= 1356 && screenWidth <= 1686"
                width="46"
                height="32"
                viewBox="0 0 46 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.9847 0.78125L24.7 6.2002L30.8922 12.5497L0.398438 12.5497V20.2128L30.8922 20.2128L24.7 26.5623L29.9847 31.9813L45.1984 16.3813L29.9847 0.78125Z"
                  fill="#70C1FF"
                />
              </svg>
              <svg
                v-if="screenWidth >= 640 && screenWidth < 1356"
                width="23"
                height="16"
                viewBox="0 0 23 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9924 0.394531L12.35 3.104L15.4461 6.27874L0.199219 6.27874V10.1103L15.4461 10.1103L12.35 13.2851L14.9924 15.9945L22.5992 8.19453L14.9924 0.394531Z"
                  fill="#70C1FF"
                />
              </svg>
              <svg
                v-if="screenWidth <= 430"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="18"
                viewBox="0 0 25 18"
                fill="none"
              >
                <path
                  d="M16.4157 0.5625L13.4835 3.56915L16.9192 7.0921L0 7.09209V11.3439L16.9192 11.3439L13.4835 14.8669L16.4157 17.8735L24.8569 9.21801L16.4157 0.5625Z"
                  fill="#70C1FF"
                />
              </svg>
              самокатного спорта
            </h2>
          </div>
          <p class="scooterHistory__text">
            Самокатный спорт зародился в США в начале 2000-х, когда появились модели детских самокатов выдерживающие
            средние нагрузки. Их начали использовать уличные экстремалы (freestyle) для исполнения трюков взятых из
            других экстрим-дисциплин (скейт, bmx).
          </p>
          <p class="scooterHistory__text">
            Стали появляться первые видео от райдеров в интернете, благодаря чему этот вид спорта стал известным всему
            миру. Он начал быстро набирать популярность в Австралии и странах Европы. Стали проводится соревнования,
            появились фирмы производящие трюковые самокаты, образовались международные команды.
          </p>
          <p class="scooterHistory__text scooterHistory__text--bold scooterHistory__text--marginTop">
            Только к 2007 году кикскутеринг начал набирать обороты в России, Беларуси и Украине.
          </p>
          <!-- Раскрытие истории -->
          <div class="scooterHistory__hiddenBlock" v-show="isShowMore">
            <LineComponent />
            <div class="scooterHistory__textBlock">
              <h4 class="scooterHistory__textBlock-year">К 2010 году</h4>
              <p class="scooterHistory__textBlock-text">
                самокатный спорт обрёл серьёзный характер, став вместо подросткового увлечения, профессиональной
                спортивной дисциплиной.
              </p>
            </div>
            <LineComponent />
            <div class="scooterHistory__textBlock">
              <h4 class="scooterHistory__textBlock-year">в 2011 году</h4>
              <p class="scooterHistory__textBlock-text">
                году начала функционировать международная самокатная ассоциация ISA (International Scooter Association),
                в которую вошли США, Австралия, Англия и Европа. Ежегодно стал проводиться Чемпионат мира по трюковому
                самокату — ISA World Championship, включающий в себя серию региональных квалификаций.
              </p>
            </div>
            <LineComponent />
            <div class="scooterHistory__textBlock">
              <h4 class="scooterHistory__textBlock-year">в 2012 году</h4>
              <p class="scooterHistory__textBlock-text scooterHistory__textBlock-text--uppercase">
                году в России открылся первый розничный магазин трюковых самокатов
                <a href="https://www.isfscoot.org/" class="scooterHistory__textBlock-text--underline">
                  Kickscootershop
                </a>
                .
              </p>
            </div>
            <LineComponent />
            <div class="scooterHistory__textBlock">
              <h4 class="scooterHistory__textBlock-year">в 2014 году</h4>
              <p class="scooterHistory__textBlock-text">
                году Россия, в лице
                <a href="https://fssport.ru/" class="scooterHistory__textBlock-text--underline">
                  РОО "Федерация самокатного спорта"
                </a>
                , стала частью ISA (на сегодняшний день
                <a href="https://www.isfscoot.org/" class="scooterHistory__textBlock-text--underline">
                  International Scooter Federation
                </a>
                или ISF). Чемпионат России по самокату является квалификацией к Чемпионату мира.
              </p>
            </div>
          </div>
        </div>
        <button class="scooterHistory__btn" @click="showMore">
          {{ readMore }}
          <svg v-if="screenWidth > 1686" class="scooterHistory__btn--rotate" width="45" height="45" fill="none">
            <circle cx="22.583" cy="22.417" r="16.119" fill="#70C1FF" transform="rotate(55.457 22.583 22.417)" />
            <path
              fill="#fff"
              d="M21.873 30.732a1.004 1.004 0 0 0 1.42 0l6.394-6.393a1.005 1.005 0 0 0-1.42-1.42L22.582 28.6l-5.682-5.683a1.005 1.005 0 0 0-1.421 1.42l6.393 6.394Zm-.294-15.92v15.21h2.009v-15.21h-2.01Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            class="scooterHistory__btn--rotate"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="18.2664" cy="18.9099" r="12.8949" transform="rotate(55.4568 18.2664 18.9099)" fill="#70C1FF" />
            <path
              d="M17.6973 25.5645C18.0112 25.8784 18.5201 25.8784 18.8339 25.5645L23.9485 20.45C24.2624 20.1361 24.2624 19.6272 23.9485 19.3134C23.6346 18.9995 23.1258 18.9995 22.8119 19.3134L18.2656 23.8597L13.7193 19.3134C13.4055 18.9995 12.8966 18.9995 12.5827 19.3134C12.2689 19.6272 12.2689 20.1361 12.5827 20.45L17.6973 25.5645ZM17.4619 12.8281L17.4619 24.9963L19.0693 24.9963L19.0693 12.8281L17.4619 12.8281Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            class="scooterHistory__btn--rotate"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.63222" cy="8.95885" r="6.44746" transform="rotate(55.4568 9.63222 8.95885)" fill="#70C1FF" />
            <path
              d="M9.34867 12.2862C9.5056 12.4431 9.76003 12.4431 9.91696 12.2862L12.4743 9.72888C12.6312 9.57195 12.6312 9.31752 12.4743 9.16059C12.3173 9.00367 12.0629 9.00367 11.906 9.16059L9.63281 11.4337L7.35966 9.16059C7.20273 9.00366 6.9483 9.00366 6.79137 9.16059C6.63444 9.31752 6.63444 9.57195 6.79137 9.72888L9.34867 12.2862ZM9.23097 5.91797L9.23097 12.002L10.0347 12.002L10.0347 5.91797L9.23097 5.91797Z"
              fill="white"
            />
          </svg>

          <svg
            v-if="screenWidth < 640"
            class="scooterHistory__btn--rotate"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.7118 39.2629C26.6459 44.8154 15.606 42.7779 10.0535 34.712C4.50096 26.6461 6.53847 15.6062 14.6044 10.0537C22.6703 4.50119 33.7102 6.53871 39.2627 14.6046C44.8152 22.6705 42.7777 33.7104 34.7118 39.2629Z"
              fill="#70C1FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32.4713 26.773L25.4387 33.8055C25.0071 34.2371 24.3074 34.2371 23.8759 33.8055L16.8433 26.773C16.4118 26.3414 16.4118 25.6417 16.8433 25.2102C17.2749 24.7786 17.9746 24.7786 18.4061 25.2102L23.5522 30.3563L23.5522 16.293L25.7624 16.293L25.7624 30.3563L30.9085 25.2102C31.34 24.7786 32.0397 24.7786 32.4713 25.2102C32.9028 25.6417 32.9028 26.3414 32.4713 26.773Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <LineComponent v-if="screenWidth <= 430" style="width: 336px" />
      <div class="scooterHistory__rightBlock">
        <div class="scooterHistory__rightTitleBlock">
          <h2 class="scooterHistory__rightTitleBlock-title">Дисциплины</h2>
          <h2 class="scooterHistory__rightTitleBlock-title scooterHistory__rightTitleBlock-title--blue">
            <svg v-if="screenWidth > 1686" width="148" height="39" fill="none">
              <path
                fill="#70C1FF"
                d="m128.755 0-6.606 6.774 7.74 7.936H.772v9.58h129.117l-7.74 7.936L128.755 39l19.017-19.5L128.755 0Z"
              />
            </svg>
            <svg
              v-if="screenWidth >= 1356 && screenWidth <= 1686"
              width="118"
              height="32"
              viewBox="0 0 118 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M102.402 0.789062L97.1171 6.20801L103.309 12.5575L0.015625 12.5575V20.2206L103.309 20.2206L97.1171 26.5701L102.402 31.9891L117.616 16.3891L102.402 0.789062Z"
                fill="#70C1FF"
              />
            </svg>
            <svg
              v-if="screenWidth >= 640 && screenWidth < 1356"
              width="60"
              height="16"
              viewBox="0 0 60 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M51.7009 0.398438L49.0586 3.10791L52.1547 6.28265L0.507812 6.28265V10.1142L52.1547 10.1142L49.0586 13.289L51.7009 15.9984L59.3078 8.19844L51.7009 0.398438Z"
                fill="#70C1FF"
              />
            </svg>
            <svg
              v-if="screenWidth <= 430"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="18"
              viewBox="0 0 25 18"
              fill="none"
            >
              <path
                d="M16.4157 0.5625L13.4835 3.56915L16.9192 7.0921L0 7.09209V11.3439L16.9192 11.3439L13.4835 14.8669L16.4157 17.8735L24.8569 9.21801L16.4157 0.5625Z"
                fill="#70C1FF"
              />
            </svg>
            мировые
          </h2>
        </div>
        <video src="@/assets/img/emptyVideo.webp" controls poster="@/assets/img/scooterHistoryVideoPoster.webp"></video>
        <div class="scooterHistory__rightBlock-more">
          <h3 class="scooterHistory__rightBlock-more-title">
            подробнее о дисциплинах
            <button v-if="screenWidth >= 640" @click="showSubjects">
              {{ readMoreSubjects }}
              <svg
                class="scooterHistory__moreSubjectsBtn"
                v-if="screenWidth > 1686"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="24.658"
                  cy="24.6583"
                  r="17.7305"
                  transform="rotate(55.4568 24.658 24.6583)"
                  fill="#70C1FF"
                />
                <path
                  d="M23.8768 33.8055C24.3084 34.2371 25.008 34.2371 25.4396 33.8055L32.4722 26.773C32.9037 26.3414 32.9037 25.6417 32.4722 25.2102C32.0406 24.7786 31.3409 24.7786 30.9094 25.2102L24.6582 31.4614L18.407 25.2102C17.9755 24.7786 17.2758 24.7786 16.8442 25.2102C16.4127 25.6417 16.4127 26.3414 16.8442 26.773L23.8768 33.8055ZM23.5531 16.293L23.5531 33.0241L25.7633 33.0241L25.7633 16.293L23.5531 16.293Z"
                  fill="white"
                />
              </svg>
              <svg
                class="scooterHistory__moreSubjectsBtn"
                v-if="screenWidth >= 1356 && screenWidth <= 1686"
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="18.0672"
                  cy="18.9177"
                  r="12.8949"
                  transform="rotate(55.4568 18.0672 18.9177)"
                  fill="#70C1FF"
                />
                <path
                  d="M17.5001 25.5645C17.8139 25.8784 18.3228 25.8784 18.6366 25.5645L23.7512 20.45C24.0651 20.1361 24.0651 19.6272 23.7512 19.3134C23.4374 18.9995 22.9285 18.9995 22.6147 19.3134L18.0684 23.8597L13.5221 19.3134C13.2082 18.9995 12.6993 18.9995 12.3855 19.3134C12.0716 19.6272 12.0716 20.1361 12.3855 20.45L17.5001 25.5645ZM17.2647 12.8281L17.2647 24.9963L18.872 24.9963L18.872 12.8281L17.2647 12.8281Z"
                  fill="white"
                />
              </svg>
              <svg
                class="scooterHistory__moreSubjectsBtn"
                v-if="screenWidth >= 640 && screenWidth < 1356"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9.03261"
                  cy="8.96666"
                  r="6.44746"
                  transform="rotate(55.4568 9.03261 8.96666)"
                  fill="#70C1FF"
                />
                <path
                  d="M8.74906 12.2901C8.90599 12.447 9.16042 12.447 9.31735 12.2901L11.8746 9.73279C12.0316 9.57586 12.0316 9.32143 11.8746 9.1645C11.7177 9.00757 11.4633 9.00757 11.3064 9.1645L9.0332 11.4377L6.76005 9.1645C6.60312 9.00757 6.34869 9.00757 6.19176 9.1645C6.03483 9.32143 6.03483 9.57586 6.19176 9.73279L8.74906 12.2901ZM8.63136 5.92187L8.63136 12.0059L9.43504 12.0059L9.43504 5.92188L8.63136 5.92187Z"
                  fill="white"
                />
              </svg>
            </button>
            <button v-if="screenWidth < 640" @click="showSubjects">
              <svg
                class="scooterHistory__moreSubjectsBtn"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="24.658"
                  cy="24.6583"
                  r="17.7305"
                  transform="rotate(55.4568 24.658 24.6583)"
                  fill="#70C1FF"
                />
                <path
                  d="M23.8768 33.8055C24.3084 34.2371 25.008 34.2371 25.4396 33.8055L32.4722 26.773C32.9037 26.3414 32.9037 25.6417 32.4722 25.2102C32.0406 24.7786 31.3409 24.7786 30.9094 25.2102L24.6582 31.4614L18.407 25.2102C17.9755 24.7786 17.2758 24.7786 16.8442 25.2102C16.4127 25.6417 16.4127 26.3414 16.8442 26.773L23.8768 33.8055ZM23.5531 16.293L23.5531 33.0241L25.7633 33.0241L25.7633 16.293L23.5531 16.293Z"
                  fill="white"
                />
              </svg>
            </button>
          </h3>
        </div>
        <MoreAboutSubjectsComponent :screenWidth="screenWidth" v-show="isReadMoreSubjects" />
      </div>
    </div>
  </section>

  <LineComponent v-if="screenWidth <= 430" style="width: 336px" />

  <img v-if="screenWidth > 640" class="aboutScooterBanner" src="@/assets/img/ScooterHistoryBanner.webp" alt="Banner" />

  <section class="questions container">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #57606f" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
</template>

<script>
import LineComponent from '@/components/LineComponent.vue';
import MoreAboutSubjectsComponent from '@/components/MoreAboutSubjectsComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import TickerComponent from '@/components/TickerComponent.vue';

export default {
  components: { QuestionComponent, TickerComponent, LineComponent, MoreAboutSubjectsComponent },
  props: {
    screenWidth: Number,
  },
  data() {
    return {
      isShowMore: false,
      readMore: 'Подробнее',
      readMoreSubjects: 'Читать далее',
      isReadMoreSubjects: false,
    };
  },
  methods: {
    showMore() {
      const svg = document.querySelector('.scooterHistory__btn--rotate');
      if (this.readMore === 'Подробнее') {
        this.readMore = 'Скрыть';
        svg.style.transform = 'rotate(180deg)';
      } else {
        this.readMore = 'Подробнее';
        svg.style.transform = 'rotate(0deg)';
      }

      this.isShowMore = !this.isShowMore;
    },
    showSubjects() {
      const svg = document.querySelector('.scooterHistory__moreSubjectsBtn');
      if (this.readMoreSubjects === 'Читать далее') {
        this.readMoreSubjects = 'Скрыть';
        svg.style.transform = 'rotate(180deg)';
      } else {
        this.readMoreSubjects = 'Читать далее';
        svg.style.transform = 'rotate(0deg)';
      }

      this.isReadMoreSubjects = !this.isReadMoreSubjects;
    },
  },
};
</script>

<style lang="scss" scoped></style>
