<template>
	<table>
		<thead>
			<tr>
				<td></td>
				<th>ПН</th>
				<th>ВТ</th>
				<th>СР</th>
				<th>ЧТ</th>
				<th>ПТ</th>
				<th>СБ</th>
				<th>ВС</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>20:00</th>
				<td rowspan="4"></td>
				<td rowspan="4" class="booked"></td>
				<td rowspan="2" class="booked"></td>
				<td rowspan="2"></td>
				<td rowspan="3" class="booked"></td>
				<td rowspan="4"></td>
				<td rowspan="4" class="booked"></td>
			</tr>
			<tr>
				<th>22:00</th>
			</tr>
			<tr>
				<th>23:00</th>
				<td rowspan="2"></td>
				<td rowspan="2" class="booked"></td>
			</tr>
			<tr>
				<th>6:00</th>
				<td rowspan="2"></td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	data() {
		return {};
	},

	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 15px;
	thead {
		td {
			background: #f6f6f6;
			width: 5%;
			text-align: center;
		}

		th {
			background: #252c37;
			width: 10%;
			text-align: center;
			color: #fff;
			font-size: 30px;
			font-style: italic;
			font-weight: 800;
			line-height: 30px;
			text-transform: uppercase;
			padding: 10px 0;
		}
	}

	tbody {
		th {
			background: #70c1ff;
			color: #fff;
			padding: 10px 0;
			text-align: center;
			font-size: 30px;
			font-style: italic;
			font-weight: 800;
			line-height: 30px;
			text-transform: uppercase;
		}

		td {
			background: #d1d1d1;
			color: #d1d1d1;
			padding: 13px 0;
			text-align: center;
			font-size: 23px;
			font-style: italic;
			font-weight: 600;
			line-height: 24px;
			text-transform: uppercase;
			cursor: pointer;

			// &:hover {
			// 	.groupSchedule__groupBtn {
			// 		color: #fff;
			// 	}

			// 	background: #252c37;
			// }
		}

		.booked {
			background: #0091ff;
		}
	}

	.individualSchedule__btn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #fff;
		text-transform: uppercase;
	}
	.groupSchedule__groupBtn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #d1d1d1;
		text-transform: uppercase;
	}
}

@media (max-width: 1686px) and (min-width: 1356px) {
	table {
	border-spacing: 5px;

	thead {
		th {
			font-size: 24px;
			line-height: 24px;
			padding: 10px 0;
		}
	}

	tbody {
		th {
			background: #252c37;
			padding: 10px 0;
			font-size: 24px;
			line-height: 24px;
		}

		td {
			padding: 10px 0;
			font-size: 24px;
			line-height: 24px;
		}

		.booked {
			background: #0091ff;
		}
	}

	.individualSchedule__btn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #fff;
		text-transform: uppercase;
	}
	.groupSchedule__groupBtn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #d1d1d1;
		text-transform: uppercase;
	}
}
}

@media (max-width: 1355px) and (min-width: 640px) {
	table {
	border-spacing: 5px;

	thead {
		th {
			font-size: 12px;
			line-height: 12px;
			padding: 5px 0;
		}
	}

	tbody {
		th {
			background: #252c37;
			padding: 5px 0;
			font-size: 12px;
			line-height: 12px;
		}

		td {
			padding: 5px 0;
			font-size: 12px;
			line-height: 12px;
		}

		.booked {
			background: #0091ff;
		}
	}

	.individualSchedule__btn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #fff;
		text-transform: uppercase;
	}
	.groupSchedule__groupBtn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #d1d1d1;
		text-transform: uppercase;
	}
}
}

@media (max-width: 430px) {
	table {
		border-spacing: 4px;
		thead {
			th {
				font-size: 15px;
				line-height: 15px;
				padding: 20px 0;
			}
		}

		tbody {
			th {
				background: #252c37;
				padding: 20px 5px;
				text-align: center;
				font-size: 15px;
				line-height: 15px;
			}
		}
	}
}
</style>
