import axiosInstance from "@/services/axiosInstance";
import axios from "axios";

const topBanner = {
  state: {
    topSlides: [], 
  },
  getters: {},
  mutations: {
    SET_DAT(state, data) {
      state.topSlides = data;
    },
  },
  actions: {
    async fetchTopSlides({ commit }) {
      try {
        const response = await axiosInstance("/topbanner");
        commit("SET_DAT", response.data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    },
  },
};

export default topBanner;
