<template>
	<img v-for="photo in photos" :key="photo.id" :src="photo.url" alt="photo" />
</template>

<script>
export default {
	props: {
		photos: Array,
	},
};
</script>

<style lang="scss" scoped></style>
