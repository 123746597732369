const footerNavigation = {
	state: {
		navigation: [
			{
				id: 1,
				title: 'ГЛАВНАЯ',
				url: '/home',
			},
			{
				id: 2,
				title: 'Самокатный спорт',
				url: '/scooter-sport',
			},
			{
				id: 3,
				title: 'Самокат-школа',
				url: '/about',
			},
			{
				id: 4,
				title: 'KSS park',
				url: '/kss-park',
			},
			{
				id: 5,
				title: 'Групповые тренировки',
				url: '/group',
			},
			{
				id: 6,
				title: 'Индивидуальные тренировки',
				url: '/individual',
			},
			{
				id: 7,
				title: 'Правила посещения тренировки',
				url: '/rules',
			},
			{
				id: 8,
				title: 'Мастер-классы',
				url: '/master-classes',
			},
			{
				id: 9,
				title: 'Обучалки',
				url: '/training',
			},
			{
				id: 10,
				title: 'Часто задаваемые вопросы',
				url: '/faq',
			},
			{
				id: 11,
				title: 'ТРЕНЕРСКИЙ СОСТАВ',
				url: '/team',
			},
			{
				id: 12,
				title: 'Мероприятия',
				url: '/events',
			},
			{
				id: 13,
				title: 'Медиа',
				url: '/media',
			},
			{
				id: 14,
				title: 'КОНТАКТЫ',
				url: '/contacts',
			},
		],

		socials: [
			{
				id: 1,
				title: 'VK',
				url: 'http://vk.com',
				svg: `
				<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.3 0C9.98392 0 0 10.0734 0 22.5C0 34.9266 9.98392 45 22.3 45C34.6162 45 44.6001 34.9266 44.6001 22.5C44.6001 10.0734 34.6162 0 22.3 0ZM30.8763 25.3852C30.8763 25.3852 32.8484 27.3492 33.3339 28.2609C33.3479 28.2797 33.3548 28.2984 33.3595 28.3078C33.5569 28.643 33.6034 28.9031 33.5058 29.0977C33.3432 29.4211 32.7857 29.5805 32.5952 29.5945H29.1109C28.8693 29.5945 28.3629 29.5312 27.7496 29.1047C27.2781 28.7719 26.8135 28.2258 26.3605 27.6938C25.6845 26.9016 25.0992 26.2172 24.5091 26.2172C24.4342 26.2171 24.3597 26.2289 24.2885 26.2523C23.8425 26.3977 23.271 27.0398 23.271 28.7508C23.271 29.2852 22.8529 29.5922 22.5579 29.5922H20.962C20.4185 29.5922 17.5868 29.4 15.0781 26.7305C12.0072 23.4609 9.24291 16.9031 9.21968 16.8422C9.04546 16.418 9.40551 16.1906 9.79808 16.1906H13.3173C13.7865 16.1906 13.9399 16.4789 14.0467 16.7344C14.1721 17.032 14.6321 18.2156 15.387 19.5469C16.6112 21.7172 17.3615 22.5984 17.9632 22.5984C18.076 22.5971 18.1868 22.5681 18.286 22.5141C19.0712 22.0734 18.9248 19.2492 18.89 18.6633C18.89 18.5531 18.8877 17.4 18.4858 16.8469C18.1978 16.4461 17.7076 16.2938 17.4103 16.2375C17.5306 16.0699 17.6892 15.9341 17.8726 15.8414C18.4115 15.5695 19.3825 15.5297 20.3465 15.5297H20.8831C21.9284 15.5437 22.1978 15.6117 22.5765 15.7078C23.343 15.893 23.3593 16.3922 23.2919 18.1008C23.271 18.5859 23.2501 19.1344 23.2501 19.7813C23.2501 19.9219 23.2432 20.0719 23.2432 20.2313C23.2199 21.1008 23.1921 22.0875 23.8007 22.493C23.88 22.5432 23.9718 22.57 24.0655 22.5703C24.2769 22.5703 24.9133 22.5703 26.6369 19.5867C27.1685 18.6264 27.6304 17.6285 28.0191 16.6008C28.0539 16.5398 28.1561 16.3523 28.2769 16.2797C28.3661 16.2338 28.4649 16.2105 28.565 16.2117H32.7021C33.1527 16.2117 33.4617 16.2797 33.5198 16.4555C33.622 16.7344 33.5012 17.5852 31.6126 20.1656L30.7694 21.2883C29.0574 23.5523 29.0574 23.6672 30.8763 25.3852Z" fill="white" />
</svg>`,
			},
			{
				id: 2,
				title: 'Instagram',
				url: 'http://#',
				svg: `
				<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="22.5" cy="22.5" r="22.5" fill="white" />
  <path d="M31.8073 23.4994C31.8073 28.64 27.64 32.8073 22.4994 32.8073C17.3587 32.8073 13.1914 28.64 13.1914 23.4994C13.1914 18.3587 17.3587 14.1914 22.4994 14.1914C27.64 14.1914 31.8073 18.3587 31.8073 23.4994ZM16.2404 23.4994C16.2404 26.9561 19.0426 29.7583 22.4994 29.7583C25.9561 29.7583 28.7583 26.9561 28.7583 23.4994C28.7583 20.0426 25.9561 17.2404 22.4994 17.2404C19.0426 17.2404 16.2404 20.0426 16.2404 23.4994Z" fill="#252C37" />
  <circle cx="31.804" cy="10.7503" r="2.9466" fill="#252C37" />
</svg>`,
			},
			{
				id: 3,
				title: 'KSS',
				url: 'http://#',
				svg: `<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="22.5" cy="22.5" r="22.5" fill="white" />
  <path d="M22.0953 18.259L22.6731 17.6286H23.3311L23.9217 18.2655V19.8757H27.4716V16.799L24.8621 14H21.1613L18.5293 16.799V21.3846L23.9249 25.1341V26.6757L23.3343 27.3681H22.6763L22.0953 26.6757V24.6605H18.5293V28.1193L21.2159 31H24.7883L27.4556 28.1193V23.2561L22.0953 19.5066V18.259Z" fill="#252C37" />
  <path d="M31.6031 18.259L32.1809 17.6286H32.8389L33.4295 18.2655V19.8757H36.9795V16.799L34.3699 14H30.6691L28.0371 16.799V21.3846L33.4327 25.1341V26.6757L32.8421 27.3681H32.1809L31.6031 26.6757V24.6605H28.0371V28.1193L30.7237 31H34.2961L36.9634 28.1193V23.2561L31.6031 19.5066V18.259Z" fill="#252C37" />
  <path d="M15.1563 21.9921L18.87 14H14.9316L12.566 19.069V14H9V17.7658V18.4549V31H12.566V24.9185L15.3617 31H19.2969L15.1563 21.9921Z" fill="#252C37" />
</svg>`,
			},
		],
		socialsBlack: [
			{
				id: 1,
				title: 'VK',
				url: 'http://vk.com',
				svg: `
				<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.3 0C9.98392 0 0 10.0734 0 22.5C0 34.9266 9.98392 45 22.3 45C34.6162 45 44.6001 34.9266 44.6001 22.5C44.6001 10.0734 34.6162 0 22.3 0ZM30.8763 25.3852C30.8763 25.3852 32.8484 27.3492 33.3339 28.2609C33.3479 28.2797 33.3548 28.2984 33.3595 28.3078C33.5569 28.643 33.6034 28.9031 33.5058 29.0977C33.3432 29.4211 32.7857 29.5805 32.5952 29.5945H29.1109C28.8693 29.5945 28.3629 29.5312 27.7496 29.1047C27.2781 28.7719 26.8135 28.2258 26.3605 27.6938C25.6845 26.9016 25.0992 26.2172 24.5091 26.2172C24.4342 26.2171 24.3597 26.2289 24.2885 26.2523C23.8425 26.3977 23.271 27.0398 23.271 28.7508C23.271 29.2852 22.8529 29.5922 22.5579 29.5922H20.962C20.4185 29.5922 17.5868 29.4 15.0781 26.7305C12.0072 23.4609 9.24291 16.9031 9.21968 16.8422C9.04546 16.418 9.40551 16.1906 9.79808 16.1906H13.3173C13.7865 16.1906 13.9399 16.4789 14.0467 16.7344C14.1721 17.032 14.6321 18.2156 15.387 19.5469C16.6112 21.7172 17.3615 22.5984 17.9632 22.5984C18.076 22.5971 18.1868 22.5681 18.286 22.5141C19.0712 22.0734 18.9248 19.2492 18.89 18.6633C18.89 18.5531 18.8877 17.4 18.4858 16.8469C18.1978 16.4461 17.7076 16.2938 17.4103 16.2375C17.5306 16.0699 17.6892 15.9341 17.8726 15.8414C18.4115 15.5695 19.3825 15.5297 20.3465 15.5297H20.8831C21.9284 15.5437 22.1978 15.6117 22.5765 15.7078C23.343 15.893 23.3593 16.3922 23.2919 18.1008C23.271 18.5859 23.2501 19.1344 23.2501 19.7813C23.2501 19.9219 23.2432 20.0719 23.2432 20.2313C23.2199 21.1008 23.1921 22.0875 23.8007 22.493C23.88 22.5432 23.9718 22.57 24.0655 22.5703C24.2769 22.5703 24.9133 22.5703 26.6369 19.5867C27.1685 18.6264 27.6304 17.6285 28.0191 16.6008C28.0539 16.5398 28.1561 16.3523 28.2769 16.2797C28.3661 16.2338 28.4649 16.2105 28.565 16.2117H32.7021C33.1527 16.2117 33.4617 16.2797 33.5198 16.4555C33.622 16.7344 33.5012 17.5852 31.6126 20.1656L30.7694 21.2883C29.0574 23.5523 29.0574 23.6672 30.8763 25.3852Z" fill="#232A34" />
</svg>`,
			},
			{
				id: 2,
				title: 'Instagram',
				url: 'http://#',
				svg: `
				<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="22.5" cy="22.5" r="22.5" fill="#232A34" />
  <path d="M31.8073 23.4994C31.8073 28.64 27.64 32.8073 22.4994 32.8073C17.3587 32.8073 13.1914 28.64 13.1914 23.4994C13.1914 18.3587 17.3587 14.1914 22.4994 14.1914C27.64 14.1914 31.8073 18.3587 31.8073 23.4994ZM16.2404 23.4994C16.2404 26.9561 19.0426 29.7583 22.4994 29.7583C25.9561 29.7583 28.7583 26.9561 28.7583 23.4994C28.7583 20.0426 25.9561 17.2404 22.4994 17.2404C19.0426 17.2404 16.2404 20.0426 16.2404 23.4994Z" fill="white" />
  <circle cx="31.804" cy="10.7503" r="2.9466" fill="white" />
</svg>`,
			},
			{
				id: 3,
				title: 'KSS',
				url: 'http://#',
				svg: `<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="22.5" cy="22.5" r="22.5" fill="#232A34" />
  <path d="M22.0953 18.259L22.6731 17.6286H23.3311L23.9217 18.2655V19.8757H27.4716V16.799L24.8621 14H21.1613L18.5293 16.799V21.3846L23.9249 25.1341V26.6757L23.3343 27.3681H22.6763L22.0953 26.6757V24.6605H18.5293V28.1193L21.2159 31H24.7883L27.4556 28.1193V23.2561L22.0953 19.5066V18.259Z" fill="white" />
  <path d="M31.6031 18.259L32.1809 17.6286H32.8389L33.4295 18.2655V19.8757H36.9795V16.799L34.3699 14H30.6691L28.0371 16.799V21.3846L33.4327 25.1341V26.6757L32.8421 27.3681H32.1809L31.6031 26.6757V24.6605H28.0371V28.1193L30.7237 31H34.2961L36.9634 28.1193V23.2561L31.6031 19.5066V18.259Z" fill="white" />
  <path d="M15.1563 21.9921L18.87 14H14.9316L12.566 19.069V14H9V17.7658V18.4549V31H12.566V24.9185L15.3617 31H19.2969L15.1563 21.9921Z" fill="white" />
</svg>`,
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default footerNavigation;
