<template>
	<swiper
		:navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
		:modules="modules"
		:slidesPerView="3.5"
		:centeredSlides="false"
		:spaceBetween="15"
		:grabCursor="true"
		class="mySwiper coachesSliderMobile__swiper"
	>
		<swiper-slide v-for="coach in coaches" :key="coach.id">
			<div class="coaches__card">
				<div class="coaches__card-img__wrapper">
					<router-link :to="coach.path"><img :src="`http://195.43.142.74/images/Coaches/${coach.avatar}`" :alt="coach.name" /></router-link>
					<router-link class="coaches__btn" :to="coach.path">
						<svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none">
							<circle cx="21" cy="21" r="15" fill="#70C1FF" transform="rotate(-34.543 21.536 21.504)" />
							<path
								fill="#fff"
								d="M29.23 22.162a.93.93 0 0 0 0-1.314l-5.916-5.915A.93.93 0 1 0 22 16.247l5.258 5.258L22 26.763a.93.93 0 0 0 1.314 1.314l5.915-5.915Zm-14.73.272h14.072v-1.858H14.5v1.858Z"
							/>
						</svg>
						<svg v-if="screenWidth <= 430" width="33" height="33" fill="none">
							<circle cx="16.154" cy="16.731" r="11.524" fill="#70C1FF" transform="rotate(-34.543 16.154 16.731)" />
							<path
								fill="#fff"
								d="M22.099 17.24a.718.718 0 0 0 0-1.017l-4.571-4.57a.718.718 0 0 0-1.016 1.015l4.063 4.063-4.063 4.063a.718.718 0 1 0 1.016 1.016l4.57-4.57Zm-11.382.21H21.59v-1.437H10.717v1.436Z"
							/>
						</svg>
					</router-link>
				</div>
				<h4 class="coaches__name">{{ coach.name }}</h4>
				<div class="coaches__rating">
					<div class="coaches__rating-stars">
						<svg
							v-for="(star, index) in 5"
							:key="index"
							xmlns="http://www.w3.org/2000/svg"
							width="13"
							height="13"
							viewBox="0 0 13 13"
							fill="none"
						>
							<path
								d="M6.23926 0.97168L7.6986 5.46307H12.4211L8.60052 8.2389L10.0599 12.7303L6.23926 9.95446L2.41865 12.7303L3.87799 8.2389L0.0573907 5.46307H4.77992L6.23926 0.97168Z"
								fill="#252C37"
							/>
						</svg>
					</div>
					{{ coach.rating }}
				</div>
				<button class="coaches__signUp-btn" v-if="addBtn">Записаться</button>
			</div>
		</swiper-slide>
		<div class="coachesSliderMobile__navigation">
			<div class="swiper-button-prev coachesSliderMobile__navigation-prev"></div>
			<div class="swiper-button-next coachesSliderMobile__navigation-next"></div>
		</div>
	</swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';
import CoachesListComponent from './CoachesListComponent.vue';

export default {
	props: {
		screenWidth: Number,
		addBtn: Boolean,
	},

	components: {
		Swiper,
		SwiperSlide,
		CoachesListComponent,
	},
	setup() {
		return {
			modules: [Navigation],
		};
	},

	computed: {
		...mapState({
			coaches: (state) => state.coaches.coaches,
		}),
	},

	mounted() {
		this.$store.dispatch('fetchCoaches');},
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';

.swiper {
	height: 160px;
}
</style>
