<template>
	<div class="individualPage__advantages-cards">
		<article class="advantages__card" v-for="card in cards" :key="card.id">
			<h2 class="advantages__card-title">
				<span>{{ card.id }}</span> {{ card.title }}
			</h2>
			<p class="advantages__card-text">{{ card.text }}</p>
		</article>
		<img class="thumb advantages__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb advantages__thumb5" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb advantages__thumb6" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img class="thumb advantages__thumb7" src="@/assets/img/thumbs2.svg" alt="thumb" />
	</div>
</template>

<script>
export default {
	props: {
		cards: Array,
	},
};
</script>

<style lang="scss" scoped></style>
