<template>
  <section class="price" id="price">
    <div class="price__wrapper container">
      <div class="price__titleBlock">
        <h3 class="price__title">стоимость</h3>
        <h3 class="price__title price__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="83"
            height="31"
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.104 0.242188L61.7982 5.54868L68.0151 11.7664L0.599609 11.7664V19.2705L68.0151 19.2705L61.7982 25.4882L67.104 30.7947L82.3784 15.5185L67.104 0.242188Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.125L31.4001 2.77825L34.5085 5.88711L0.800781 5.8871V9.63917L34.5085 9.63917L31.4001 12.748L34.053 15.4013L41.6902 7.76314L34.053 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="28.462" height="20.947" fill="none">
            <path
              fill="#DFDFDF"
              d="M17.99 0 14.35 3.638l4.263 4.263H0v5.145h18.614L14.35 17.31l3.638 3.638 10.473-10.473L17.989 0Z"
            />
          </svg>
          аренды
        </h3>
      </div>
      <PriceComponent :price="price" v-if="screenWidth > 1686" />
      <PriceComponentTablet :price="price" v-if="screenWidth >= 640 && screenWidth <= 1686" />
      <PriceComponentMobile :price="price" v-if="screenWidth <= 430" />
    </div>
  </section>
  <LineComponent />
  <section class="schedule" id="schedule">
    <div class="schedule__wrapper container">
      <div class="schedule__titleBlock">
        <h3 class="schedule__title">Расписание</h3>
        <h3 class="schedule__title schedule__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="83"
            height="32"
            viewBox="0 0 83 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.4067 0.867188L62.1009 6.17368L68.3178 12.3914L0.902344 12.3914V19.8955L68.3178 19.8955L62.1009 26.1132L67.4067 31.4197L82.6812 16.1435L67.4067 0.867188Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.125L31.4001 2.77825L34.5085 5.88711L0.800781 5.8871V9.63917L34.5085 9.63917L31.4001 12.748L34.053 15.4013L41.6902 7.76314L34.053 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="62.462" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M51.99 0 48.35 3.638l4.263 4.263H0v5.145h52.614L48.35 17.31l3.638 3.638 10.473-10.473L51.989 0Z"
            />
          </svg>
          KSS PARK {{ screenWidth <= 430 ? '' : '// м. нагорная' }}
        </h3>
      </div>
      <ul class="schedule__book-list">
        <li>
          <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.087" cy="18.5387" r="18.087" fill="#D1D1D1" />
          </svg>
          забронировано
        </li>
        <li>
          <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.087" cy="18.973" r="18.087" fill="#0091FF" />
          </svg>
          доступно к брони
        </li>
      </ul>
    </div>
    <div class="schedule__table container">
      <!-- <ParkScheduleComponent /> -->
			<img src="@/assets/img/groupSchedule.webp" alt="Расписание" />
    </div>
  </section>
  <LineComponent />
  <AdditionalComponent :additional="additional" :screenWidth="screenWidth" />

  <LineComponent v-if="screenWidth <= 430" />
  <AboutParkRulesComponent v-if="screenWidth <= 430" :screenWidth="screenWidth" />
  <LineComponent v-if="screenWidth <= 430" />
  <section class="feedback" v-if="screenWidth <= 430">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AdditionalComponent from '@/components/AdditionalComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import PriceComponent from '@/components/PriceComponent.vue';
import PriceComponentMobile from '@/components/PriceComponentMobile.vue';
import ParkScheduleComponent from '@/components/ParkScheduleComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';
import AboutParkRulesComponent from '@/components/AboutParkRulesComponent.vue';
import PriceComponentTablet from '@/components/PriceComponentTablet.vue';

export default {
  components: {
    AdditionalComponent,
    LineComponent,
    PriceComponent,
    PriceComponentMobile,
    PriceComponentTablet,
    ParkScheduleComponent,
    FeedbackSliderComponent,
    AboutParkRulesComponent,
  },
  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      group: (state) => state.advantagesCards.group,
      price: (state) => state.price.park,
      additional: (state) => state.price.additionalFull,
    }),
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
