<template>
  <div class="lessonFormats__blueCard">
    <img
      class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
      src="@/assets/img/BlueCard/blueRight.webp"
      alt="blueCard bg"
      v-show="isShowIndividual"
    />
    <img
      class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
      src="@/assets/img/BlueCard/lightblueLeft.webp"
      alt="blueCard bg"
      v-show="isShowIndividual"
    />
    <img
      class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
      src="@/assets/img/BlueCard/blueLeft.webp"
      alt="blueCard bg"
      v-show="isShowGroup"
    />
    <img
      class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
      src="@/assets/img/BlueCard/lightblueRight.webp"
      alt="blueCard bg"
      v-show="isShowGroup"
    />

    <div class="lessonFormats__content">
      <div class="lessonFormats__group">
        <button class="lessonFormats__lessonTitle lessonFormats__lessonTitle--marginRight" @click="showGroup">
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            v-show="isShowGroup"
            width="25"
            height="7"
            viewBox="0 0 25 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.4821 3.98597C24.6383 3.82976 24.6383 3.57649 24.4821 3.42028L21.9365 0.8747C21.7803 0.71849 21.527 0.71849 21.3708 0.8747C21.2146 1.03091 21.2146 1.28418 21.3708 1.44039L23.6335 3.70313L21.3708 5.96587C21.2146 6.12208 21.2146 6.37534 21.3708 6.53155C21.527 6.68776 21.7803 6.68776 21.9365 6.53155L24.4821 3.98597ZM0.199219 4.10313L24.1992 4.10313L24.1992 3.30313L0.199219 3.30312L0.199219 4.10313Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            v-show="isShowGroup"
            width="50"
            height="13"
            viewBox="0 0 50 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.9661 6.75319C49.2785 6.44077 49.2785 5.93424 48.9661 5.62182L43.8749 0.530649C43.5625 0.21823 43.056 0.21823 42.7435 0.530649C42.4311 0.843069 42.4311 1.3496 42.7435 1.66202L47.269 6.1875L42.7435 10.713C42.4311 11.0254 42.4311 11.5319 42.7435 11.8444C43.056 12.1568 43.5625 12.1568 43.8749 11.8444L48.9661 6.75319ZM0.400391 6.9875L48.4004 6.9875L48.4004 5.3875L0.400391 5.3875L0.400391 6.9875Z"
              fill="white"
            />
          </svg>
          групповые тренировки
        </button>
        <div class="lessonFormats__description-toggler" v-show="isShowGroup">
          <img
            class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--linesGroup"
            src="@/assets/img/BlueCard/lines.webp"
            alt="blueCard bg"
          />
          <p class="lessonFormats__description">
            Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию, так и
            педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта подрастающему
            поколению и ко всем находят свой подход.
          </p>
          <button class="lessonFormats__btn ms_booking">записаться на тренировку</button>
        </div>
      </div>
      <div class="lessonFormats__individual">
        <button class="lessonFormats__lessonTitle" @click="showIndividual">
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            v-show="isShowIndividual"
            width="25"
            height="7"
            viewBox="0 0 25 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.4821 3.98597C24.6383 3.82976 24.6383 3.57649 24.4821 3.42028L21.9365 0.8747C21.7803 0.71849 21.527 0.71849 21.3708 0.8747C21.2146 1.03091 21.2146 1.28418 21.3708 1.44039L23.6335 3.70313L21.3708 5.96587C21.2146 6.12208 21.2146 6.37534 21.3708 6.53155C21.527 6.68776 21.7803 6.68776 21.9365 6.53155L24.4821 3.98597ZM0.199219 4.10313L24.1992 4.10313L24.1992 3.30313L0.199219 3.30312L0.199219 4.10313Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            v-show="isShowIndividual"
            width="50"
            height="13"
            viewBox="0 0 50 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.9661 6.75319C49.2785 6.44077 49.2785 5.93424 48.9661 5.62182L43.8749 0.530649C43.5625 0.21823 43.056 0.21823 42.7435 0.530649C42.4311 0.843069 42.4311 1.3496 42.7435 1.66202L47.269 6.1875L42.7435 10.713C42.4311 11.0254 42.4311 11.5319 42.7435 11.8444C43.056 12.1568 43.5625 12.1568 43.8749 11.8444L48.9661 6.75319ZM0.400391 6.9875L48.4004 6.9875L48.4004 5.3875L0.400391 5.3875L0.400391 6.9875Z"
              fill="white"
            />
          </svg>
          индивидуальные тренировки
        </button>
        <div class="lessonFormats__description-toggler" v-show="isShowIndividual">
          <img
            class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--linesIndividual"
            src="@/assets/img/BlueCard/lines.webp"
            alt="blueCard bg"
          />
          <p class="lessonFormats__description">
            Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию, так и
            педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта подрастающему
            поколению и ко всем находят свой подход.
          </p>
          <button class="lessonFormats__btn ms_booking">записаться на тренировку</button>
        </div>

        <router-link class="lessonFormats__link" :to="isShowIndividual ? '/individual' : '/group'">
          читать больше
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            width="15"
            height="7"
            viewBox="0 0 15 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4832 3.58362C14.6394 3.42741 14.6394 3.17415 14.4832 3.01794L11.9376 0.472354C11.7814 0.316144 11.5282 0.316144 11.372 0.472354C11.2158 0.628564 11.2158 0.88183 11.372 1.03804L13.6347 3.30078L11.372 5.56352C11.2158 5.71973 11.2158 5.973 11.372 6.12921C11.5282 6.28542 11.7814 6.28542 11.9376 6.12921L14.4832 3.58362ZM0.400391 3.70078H14.2004V2.90078H0.400391V3.70078Z"
              fill="#FEFEFE"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="30"
            height="13"
            viewBox="0 0 30 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.9665 6.9485C29.2789 6.63608 29.2789 6.12955 28.9665 5.81713L23.8753 0.725958C23.5629 0.413539 23.0563 0.413539 22.7439 0.725958C22.4315 1.03838 22.4315 1.54491 22.7439 1.85733L27.2694 6.38281L22.7439 10.9083C22.4315 11.2207 22.4315 11.7272 22.7439 12.0397C23.0563 12.3521 23.5629 12.3521 23.8753 12.0397L28.9665 6.9485ZM0.800781 7.18281H28.4008V5.58281H0.800781V7.18281Z"
              fill="#FEFEFE"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <img class="thumb lessonFormats__thumb11" src="@/assets/img/thumbs2.svg" alt="thumb" />
    <img class="thumb lessonFormats__thumb12" src="@/assets/img/thumbs2.svg" alt="thumb" />
    <img class="thumb lessonFormats__thumb13" src="@/assets/img/thumbs2.svg" alt="thumb" />
  </div>
</template>

<script>
export default {
  props: {
    screenWidth: Number,
  },
  data() {
    return {
      isShowIndividual: true,
      isShowGroup: false,
    };
  },

  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://w388672.yclients.com/widgetJS`;
    script.charset = 'UTF-8';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
  },

  methods: {
    showGroup() {
      this.isShowGroup = true;
      this.isShowIndividual = false;
    },
    showIndividual() {
      this.isShowGroup = false;
      this.isShowIndividual = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
