const headerNavigation = {
	state: {
		navigation: [
			{
				id: 1,
				title: 'О НАС',
				url: '/about',
			},
			{
				id: 2,
				title: 'ОБУЧЕНИЕ',
				url: '#',
			},
			{
				id: 3,
				title: 'ТРЕНЕРСКИЙ СОСТАВ',
				url: '/team',
			},
			{
				id: 4,
				title: 'УСЛУГИ И ЦЕНЫ',
				url: '/services-and-price',
			},
			{
				id: 5,
				title: 'СОБЫТИЯ',
				url: '#',
			},
		],
		submenu: [
			[
				{
					id: 1,
					title: 'Групповые тренировки',
					url: '/group',
				},
				{
					id: 2,
					title: 'Индивидуальные тренировки',
					url: '/individual',
				},
				{
					id: 3,
					title: 'Обучалки',
					url: '/training',
				},
			],
			[
				{
					id: 1,
					title: 'Самокатный спорт',
					url: '/scooter-sport',
				},
				{
					id: 2,
					title: 'Самокат-школа',
					url: '/about',
				},
				{
					id: 3,
					title: 'О скейт-парке',
					url: '/kss-park',
				},
				{
					id: 4,
					title: 'Групповые тренировки',
					url: '/group',
				},
				{
					id: 5,
					title: 'Индивидуальные тренировки',
					url: '/individual',
				},
				{
					id: 6,
					title: 'Правила посещения тренировки',
					url: '/rules',
				},
				{
					id: 7,
					title: 'Часто задаваемые вопросы',
					url: '/faq',
				},
			],
			[
				{
					id: 1,
					title: 'Мероприятия',
					url: '/events',
				},
				{
					id: 2,
					title: 'Медиа',
					url: '/media',
				},
			],
			[
				{
					id: 1,
					title: 'Групповые тренировки',
					url: '/services-and-price/group',
				},
				{
					id: 2,
					title: 'Индивидуальные тренировки',
					url: '/services-and-price/individual',
				},
				{
					id: 3,
					title: 'Свободное катание',
					url: '/services-and-price/free-driving',
				},
				{
					id: 4,
					title: 'Аренда парка',
					url: '/services-and-price/park',
				},
				{
					id: 5,
					title: 'Прокат',
					url: '/services-and-price/rental',
				},
				{
					id: 6,
					title: 'Организация мероприятий',
					url: '/services-and-price/events',
				},
			],
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default headerNavigation;
