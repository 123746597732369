import axiosInstance from "@/services/axiosInstance";

const feedbackCards = {
  state: {
    feedbackState: [],
  },
  getters: {},
  mutations: {
    SET_FEEDBACK(state, data) {
      state.feedbackState = data;
    },
  },
  actions: {
    async fetchFeedback({ commit }) {
      try {
        const response = await axiosInstance("/feedbacks");

        commit("SET_FEEDBACK", response.data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    },
  },
};

export default feedbackCards;
